import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { LocalesType } from '../Interface/LocalesInterface';
import { MessageKeyTypes } from '../Interface/MessageKeyTypes';

interface LocalizationContextInterface {
  locale: LocalesType;
  messages: { [key in MessageKeyTypes]: string } | undefined;
}

const initialState = {
  locale: 'en' as LocalesType,
  messages: undefined,
};

const LocalizationContext =
  createContext<LocalizationContextInterface>(initialState);

export const LocalizationProvider = ({
  children,
  locale = 'en',
  localeData,
}: any) => {
  const [messages, setMessages] = useState<
    | {
        [key in MessageKeyTypes]: string;
      }
    | undefined
  >(localeData);

  useEffect(() => {
    async function loadLocale() {
      try {
        const localeData = await import(
          `../localization/languages/${locale}.ts`
        );

        setMessages(localeData.default);
      } catch (error) {
        console.error('Failed to load locale:', error);
        const fallbackLocaleData = await import(
          `../localization/languages/${'en'}.ts`
        );
        setMessages(fallbackLocaleData.default);
      }
    }

    loadLocale();
  }, [locale]);

  const memoValue = useMemo(() => ({ locale, messages }), [locale, messages]);

  return (
    <LocalizationContext.Provider value={memoValue}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocale = () => useContext(LocalizationContext);
