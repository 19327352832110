export const getSessionStorageString = (key: string) => {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const serializedState = sessionStorage.getItem(key);
    if (serializedState == null) {
      return null;
    }
    return serializedState;
  } catch (err) {
    return null;
  }
};

// Getting Data Back from LS
export function getSessionStorage(key: string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const serializedState = sessionStorage.getItem(key);
    let localItemVal = '';
    if (serializedState == null) {
      return null;
    }
    try {
      localItemVal = JSON.parse(serializedState);
      return localItemVal;
    } catch (e) {
      // return primitive data types such as normal strings
      return serializedState;
    }
  } catch (err) {
    // process.env.NODE_ENV === 'development' &&
    //   console.log('err in getSessionStorage:', err);
    return null;
  }
}

// Setting Data at LS
export function saveSessionStorage(key: string, state: object | string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    if (typeof state === 'object') {
      let serializedState = JSON.stringify(state);
      sessionStorage.setItem(key, serializedState);
    } else {
      sessionStorage.setItem(key, state);
    }
  } catch (err) {
    // console.log('Some Problem With Local Storage Writing');
  }
}

// Removing Data at LS
export function deleteSessionStorage(key: string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    sessionStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
}

export function saveSessionStorageExp(
  key: string,
  data: object | boolean,
  expirationMin: number
) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const expirationMS = expirationMin * 60 * 1000;
    const record = {
      value: JSON.stringify(data),
      timestamp: new Date().getTime() + expirationMS,
    };
    sessionStorage.setItem(key, JSON.stringify(record));
    return data;
  } catch (e) {
    return false;
  }
}

export function getSessionStorageExp(key: string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const item = sessionStorage.getItem(key);
    if (item === null) {
      return false;
    }

    const record = JSON.parse(item);
    if (!record || !record.timestamp) {
      return false;
    }

    if (new Date().getTime() >= record.timestamp) {
      sessionStorage.removeItem(key); // delete the obsolete item
      return false;
    }

    return JSON.parse(record.value);
  } catch (e) {
    return false;
  }
}
