import { NextRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { saveEventV3 } from './eventTracking';
const cookies = new Cookies();
// import { getLocalStorageString } from './localStorageUtils';

// type AcquisitionChannels = 'srsltid' | 'gclid';

// const acquisitionChannels: Array<AcquisitionChannels> = ['srsltid', 'gclid'];

// utm_source=Resident+Evil&utm_medium=Design&utm_campaign=RE_Twitter&utm_id=RE
// twitter post

// currency=INR&utm_source=google-ads&utm_campaign=all+products+india&utm_agid=140667954608&utm_term=&creative=590494907165&device=c
// google-ads

// currency=INR&srsltid=AdGWZVQZudYy0yqJ2hjywDE9VEfytf_OUiZ8NYGGeTXtf9EpCStAUynVEAA
// google-shopping

export const setAcquisitionChannel = (x: NextRouter) => {
  const queryParams = x.query;

  let source: string | null = null;
  let campaign: string | null = null;

  if (typeof queryParams['utm_source'] === 'string') {
    source = queryParams['utm_source'];
  } else if (typeof queryParams['srsltid'] === 'string') {
    source = 'google-shopping';
  } else if (typeof queryParams['fbclid'] === 'string') {
    source = 'facebook-shopping';
  } else if (typeof queryParams['ScCid'] === 'string') {
    source = 'snapchat-shopping';
  }

  if (typeof queryParams['utm_campaign'] === 'string') {
    campaign = queryParams['utm_campaign'];
  }

  const sourceInCookies = cookies.get('driffle_acq');

  if (source !== sourceInCookies && typeof source === 'string') {
    setTimeout(() => {
      // const cookies2 = new Cookies();
      // let deviceId = cookies2.get('device_uid');
      // const postData = {
      //   acquisitionChannel: source,
      //   acquisitionCampaign: campaign,
      //   deviceId,
      //   queryParams,
      // };

      saveEventV3({
        category: 'acquisition-channel',
        action: 'load',
        label: '',
        properties: campaign?.toString() || '',
        value: [source?.toString() || ''],
        jsonData: {
          query: queryParams,
        },
        from: x,
      });
      // axios
      //   .post(apiConstants.frontendEvent + '/acquisition-channel', postData, {
      //     withCredentials: true,
      //   })
      //   .then(() => {})
      //   .catch(() => {});
    }, 10000);
  }
};
