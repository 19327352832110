interface Props {
  error: any;
  resetErrorBoundary?: any;
}

const ErrorFallbackComponent = ({ error, resetErrorBoundary }: Props) => {
  return (
    <div role="alert" style={{ padding: '8px' }}>
      <p>Something went wrong</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again later</button>
    </div>
  );
};

export default ErrorFallbackComponent;
