import { createContext, ReactElement, useMemo, useReducer } from 'react';

interface SnackbarInitialStateInterface {
  state: boolean;
  type: 'error' | 'warning' | 'info' | 'success' | '';
  message: string;
}

const snackbarInitialState: SnackbarInitialStateInterface = {
  state: false,
  type: '',
  message: '',
};

export interface SnackbarActionInterface {
  payload: string;
  type: 'failure' | 'success' | 'close_snackbar';
}

const snackbarReducer = (
  state: SnackbarInitialStateInterface,
  action: SnackbarActionInterface
): SnackbarInitialStateInterface => {
  switch (action.type) {
    case 'failure':
      const notifyFailureState: SnackbarInitialStateInterface = {
        state: true,
        type: 'error',
        message: action.payload,
      };
      return notifyFailureState;

    case 'close_snackbar':
      const closeSnackbar: SnackbarInitialStateInterface = {
        state: false,
        type: '',
        message: '',
      };
      return closeSnackbar;

    case 'success':
      const notifySuccessState: SnackbarInitialStateInterface = {
        state: true,
        type: 'success',
        message: action.payload,
      };
      return notifySuccessState;
    default:
      return snackbarInitialState;
  }
};

interface Props {
  children: ReactElement;
}

const SnackbarContext = createContext<{
  snackbarState: SnackbarInitialStateInterface;
  dispatchSnackbar: (action: SnackbarActionInterface) => void;
}>({ snackbarState: snackbarInitialState, dispatchSnackbar: () => {} });
const { Provider } = SnackbarContext;

const SnackbarProvider = (props: Props) => {
  const [snackbarState, dispatchSnackbar] = useReducer(
    snackbarReducer,
    snackbarInitialState
  );

  const value = useMemo(() => {
    return { snackbarState, dispatchSnackbar };
  }, [snackbarState, dispatchSnackbar]);

  return <Provider value={value}>{props.children}</Provider>;
};

export { SnackbarContext, SnackbarProvider };
