import axios from 'axios';
import { apiConstants } from '../../constants/apiConstants';

export const getUserAuthService = async () => {
  try {
    const uri = apiConstants.server + '/public/customer/auth';
    const response = await axios.get(uri, {
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const getSellerAuthService = async () => {
  try {
    const uri = apiConstants.sellerServer;
    const response = await axios.get(uri, {
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};
