import Cookies from 'universal-cookie';
import { CartResponseInterface } from '../Interface/CartItemInterface';
import { trackEvent } from './eventTracking';

const cookies = new Cookies();

export type GTMEventNames =
  | 'view_item'
  | 'select_item'
  | 'view_cart'
  | 'view_checkout'
  | 'purchase'
  | 'purchase_initiate'
  | 'purchase_cancelled'
  | 'proceed_to_checkout'
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'update_cart'
  | 'remove_from_cart'
  | 'apply_promotion'
  | 'view_home_page'
  | 'view_collection_list'
  | 'view_product_list'

  // membership events
  | 'buy_membership'
  | 'toggle_membership'
  | 'remove_membership'
  | 'view_membership_page'

  // other events
  | 'login'
  | 'sign_up'
  | 'search'
  | 'view_store'
  | 'banner_click'
  | 'view_sale_page'
  | 'view_collection_page'
  | 'view_footer'
  | 'footer_link_click'
  | 'custom_btn_click'

  // eSim events
  | 'esim_purchase';

export const triggerGtmSelectItem = (
  category: string,
  product: {
    productSku: number | string | null;
    title: string;
    productType: string;
    price: number;
    platform?: string | null;
    regionName: string;
    discount: number;
    inStock: boolean;
  }
) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'select_item',
        ecommerce: {
          item_list_id: category?.toLocaleLowerCase()?.replace(' ', '_'),
          item_list_name: category,
          items: [
            {
              item_id: product?.productSku ?? '',
              item_name: product?.title ?? '',
              item_category: product?.productType ?? '',
              price: product?.price ?? 0,
              currency: 'EUR',
              discount: product?.discount ?? '',
              item_brand: product?.platform ?? '',
              item_variant: product?.regionName ?? '',
              affiliation: cookies.get('drif_acq') ?? '',
            },
          ],
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewItem = (product: {
  productSku: number | string | null;
  title: string;
  productType: string;
  price: number;
  platform?: string | null;
  regionName: string;
  discount: number;
  inStock: boolean;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_item',
        ecommerce: {
          currency: 'EUR',
          value: product?.price ?? 0,
          items: [
            {
              item_id: product?.productSku ?? '',
              item_name: product?.title ?? '',
              item_category: product?.productType ?? '',
              price: product?.price ?? 0,
              currency: 'EUR',
              discount: product?.discount ?? '',
              item_brand: product?.platform ?? '',
              item_variant: product?.regionName ?? '',
              affiliation: cookies.get('drif_acq') ?? '',
            },
          ],
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewCart = (cart: CartResponseInterface) => {
  try {
    let itemsArray = [];
    for (let i = 0; i < cart.cartItems.length; i++) {
      itemsArray.push({
        item_id: cart?.cartItems[i]?.product?.productSku ?? '',
        item_name: cart.cartItems[i]?.product?.title ?? '',
        item_category: String(cart.cartItems[i].product.productTypes),
        price: cart.cartItems[i].basePrice,
        currency: 'EUR',
        quantity: cart.cartItems[i].qty,
        item_brand: cart.cartItems[i].product.platform,
        item_variant: '',
        affiliation: cookies.get('drif_acq') ?? '',
      });
    }
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_cart',
        ecommerce: {
          currency: 'EUR',
          value: cart.baseTotal,
          items: itemsArray,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewCheckout = (cart: CartResponseInterface) => {
  try {
    let itemsArray = [];
    for (let i = 0; i < cart.cartItems.length; i++) {
      itemsArray.push({
        item_id: cart?.cartItems[i]?.product?.productSku ?? '',
        item_name: cart.cartItems[i]?.product?.title ?? '',
        item_category: String(cart.cartItems[i].product.productTypes),
        price: cart.cartItems[i].basePrice,
        currency: 'EUR',
        quantity: cart.cartItems[i].qty,
        item_brand: cart.cartItems[i].product.platform,
        item_variant: '',
        affiliation: cookies.get('drif_acq') ?? '',
      });
    }
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_checkout',
        ecommerce: {
          currency: 'EUR',
          value: cart.baseTotal,
          items: itemsArray,
        },
      },
    });
  } catch (error) {}
};

export const triggerProceedToCheckout = ({
  position,
}: {
  position: 'cart_summary' | 'mobile_checkout_sticky';
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'proceed_to_checkout',
        position: position,
      },
    });
  } catch (error) {}
};

export const triggerGtmPaymentFailed = (
  cart: CartResponseInterface,
  reason: string
) => {
  try {
    let itemsArray = [];
    for (let i = 0; i < cart.cartItems.length; i++) {
      itemsArray.push({
        item_id: cart?.cartItems[i]?.product?.productSku ?? '',
        item_name: cart.cartItems[i]?.product?.title ?? '',
        item_category: String(cart.cartItems[i].product.productTypes),
        price: cart.cartItems[i].basePrice,
        currency: 'EUR',
        quantity: cart.cartItems[i].qty,
        item_brand: cart.cartItems[i].product.platform,
        item_variant: '',
        affiliation: cookies.get('drif_acq') ?? '',
      });
    }
    trackEvent('gtm_event', {
      eventData: {
        event: 'purchase_cancelled',
        ecommerce: {
          currency: 'EUR',
          value: cart.baseTotal,
          items: itemsArray,
          reason: reason,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmPaymentSuccess = ({
  orderId,
  orderAmount,
  currency,
  orderItems,
  orderEmail,
  page,
  fbObjectPurchase,
}: {
  orderId: string;
  orderAmount: number;
  currency: string;
  orderItems: Array<any>;
  orderEmail: string;
  page: string;
  fbObjectPurchase: Array<any>;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'purchase',
        ecommerce: {
          page: page,
          transaction_id: orderId || '',
          value: String(orderAmount) || '',
          currency: currency || 'EUR',
          orderEmail: orderEmail || '',
          items: orderItems,
          fbObjectPurchase,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmPaymentInitiate = (cart: CartResponseInterface) => {
  try {
    let itemsArray = [];
    for (let i = 0; i < cart.cartItems.length; i++) {
      itemsArray.push({
        item_id: cart?.cartItems[i]?.product?.productSku ?? '',
        item_name: cart.cartItems[i]?.product?.title ?? '',
        item_category: String(cart.cartItems[i].product.productTypes),
        price: cart.cartItems[i].basePrice,
        currency: 'EUR',
        quantity: cart.cartItems[i].qty,
        item_brand: cart.cartItems[i].product.platform,
        item_variant: '',
        affiliation: cookies.get('drif_acq') ?? '',
      });
    }
    trackEvent('gtm_event', {
      eventData: {
        event: 'purchase_initiate',
        ecommerce: {
          currency: 'EUR',
          value: cart.baseTotal,
          items: itemsArray,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmAddPaymentInfo = ({
  paymentMethod,
  currency,
  items,
  value,
}: {
  paymentMethod: string;
  currency: string;
  value: number;
  items: Array<any>;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'add_payment_info',
        ecommerce: {
          paymentMethod: paymentMethod,
          currency: currency,
          value: value,
          items: items,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmAddShippingInfo = ({ email }: { email: string }) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'add_shipping_info',
        ecommerce: {
          orderEmail: email,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmAddToCart = ({
  value,
  currency,
  items,
  withMembership,
}: {
  value: number;
  currency: string;
  items: Array<any>;
  withMembership: boolean;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'add_to_cart',
        ecommerce: {
          currency: currency,
          value: value,
          items: items,
          withMembership: withMembership,
          fromPage: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmAddToWishlist = ({
  value,
  items,
  currency,
}: {
  value: number;
  items: Array<any>;
  currency: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'add_to_wishlist',
        ecommerce: {
          currency: currency,
          value: value,
          items: items,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmUpdateCartQuantity = ({
  action,
  currency,
  value,
  items,
}: {
  action: 'add' | 'remove';
  currency: string;
  value: number;
  items: Array<any>;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'update_cart',
        ecommerce: {
          currency: currency,
          value: value,
          action: action,
          items: items,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmRemoveItemFromCart = ({
  currency,
  value,
  items,
}: {
  currency: string;
  value: number;
  items: Array<any>;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'remove_from_cart',
        ecommerce: {
          currency: currency,
          value: value,
          items: items,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmApplyPromotion = ({
  promoName,
}: {
  promoName: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'apply_promotion',
        ecommerce: {
          value: promoName,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmLogin = ({ method }: { method: string }) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'login',
        method: method,
      },
    });
  } catch (error) {}
};

export const triggerGtmSignUp = ({ method }: { method: string }) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'sign_up',
        method: method,
      },
    });
  } catch (error) {}
};

export const triggerGtmSearch = ({ searchText }: { searchText: string }) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'search',
        searchText: searchText,
      },
    });
  } catch (error) {}
};

export const triggerBannerClick = ({
  category,
  label,
  properties,
  value,
  position,
}: {
  category: string;
  label: string;
  properties: string;
  value: string;
  position: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'banner_click',
        ecommerce: {
          category: category,
          label: label,
          properties: properties,
          value: value,
          position: position,
          from: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmSalePageView = ({ saleName }: { saleName: string }) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_sale_page',
        ecommerce: {
          page: window.location.pathname,
          value: saleName,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmCollectionPageView = ({
  collectionName,
}: {
  collectionName: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_collection_page',
        ecommerce: {
          page: window.location.pathname,
          value: collectionName,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmMembershipPageView = () => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_membership_page',
      },
    });
  } catch (error) {}
};

export const triggerGtmBuyMembership = ({
  membershipName,
  value,
  currency,
  period,
}: {
  membershipName: string;
  value: number;
  currency: string;
  period: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'buy_membership',
        ecommerce: {
          page: window.location.pathname,
          value: value,
          membershipName: membershipName,
          period: period,
          currency: currency,
        },
      },
    });
  } catch (error) {}
};

export const triggerRemoveMembershipFromCart = () => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'remove_membership',
        ecommerce: {
          fromPage: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerToggleMembership = ({
  action,
}: {
  action: 'add' | 'remove';
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'toggle_membership',
        ecommerce: {
          page: window.location.pathname,
          action: action,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewFooter = () => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_footer',
        ecommerce: {
          page: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmFooterLinkClick = ({
  label,
  link,
}: {
  label: string;
  link: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'footer_link_click',
        ecommerce: {
          label: label,
          link: link,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewHomePage = () => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_home_page',
        ecommerce: {
          page: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewCollectionList = () => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_collection_list',
        ecommerce: {
          page: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmViewProductList = ({
  productListName,
}: {
  productListName: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'view_product_list',
        ecommerce: {
          page: window.location.pathname,
          value: productListName,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmCustomBtnClick = ({
  label,
  value,
  category,
}: {
  label: string;
  value: string;
  category: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'custom_btn_click',
        ecommerce: {
          label,
          value,
          category,
          page: window.location.pathname,
        },
      },
    });
  } catch (error) {}
};

export const triggerGtmESimPurchase = ({
  orderId,
  orderAmount,
  currency,
  orderItems,
  orderEmail,
  page,
}: {
  orderId: string;
  orderAmount: number;
  currency: string;
  orderItems: Array<any>;
  orderEmail: string;
  page: string;
}) => {
  try {
    trackEvent('gtm_event', {
      eventData: {
        event: 'esim_purchase',
        ecommerce: {
          page: page,
          transaction_id: orderId || '',
          value: String(orderAmount) || '',
          currency: currency || 'EUR',
          orderEmail: orderEmail || '',
          items: orderItems,
        },
      },
    });
  } catch (error) {}
};
