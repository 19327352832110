import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';
import { darkTheme, eSimTheme, lightTheme } from '../constants/theme';
import { useAppSelector } from '../redux/hooks';

export type ThemeContextType = {
  theme: string;
  changeTheme: (theme: string) => void;
};

export const AppThemeContext = createContext<ThemeContextType | null>(null);

export const useTheme = () => useContext(AppThemeContext);

export const AppThemeProvider = ({ children }: any) => {
  const router = useRouter();
  const { theme } = useAppSelector((state) => state.theme);

  const [isESimTheme, setIsESimTheme] = useState<boolean>(false);
  const [themeMode, setThemeMode] = useState<string>(theme ?? 'dark');

  const themeChecker = useCallback(() => {
    const cookies = new Cookies();
    const theme = cookies.get('theme');

    if (router.pathname.startsWith('/esim')) {
      setIsESimTheme(true);
    } else {
      setIsESimTheme(false);
      if (theme === 'dark') {
        setThemeMode('dark');
      } else if (theme === 'light') {
        setThemeMode('light');
      }
    }
    // else {
    //   const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    //   if (darkThemeMq.matches) {
    //     cookies.set('theme', 'dark', { maxAge: 1000000 });
    //     setThemeMode('dark');
    //   } else {
    //     cookies.set('theme', 'light', { maxAge: 1000000 });
    //     setThemeMode('light');
    //   }
    // }
  }, [router]);

  useEffect(() => {
    themeChecker();
  }, [themeChecker]);

  return (
    <AppThemeContext.Provider
      value={{ theme: themeMode, changeTheme: setThemeMode }}
    >
      <ThemeProvider
        theme={
          isESimTheme
            ? eSimTheme
            : themeMode === 'dark'
            ? darkTheme
            : lightTheme
        }
      >
        {children}
      </ThemeProvider>
    </AppThemeContext.Provider>
  );
};
