import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { useAppSelector } from '../../redux/hooks';
import { getSellerAuthService } from '../services';

interface AuthContextInterface {
  auth: 'loading' | 'auth' | 'unauth';
  sellerAuth: 'loading' | 'auth' | 'unauth';
  checkUserAuthStatus: () => void;
  checkSellerAuthStatus: () => void;
  storeUser: 'admin' | 'agent' | null;
}

const initialState: AuthContextInterface = {
  auth: 'loading',
  sellerAuth: 'loading',
  checkUserAuthStatus: () => {},
  checkSellerAuthStatus: () => {},
  storeUser: null,
};

export const AuthContext = createContext<AuthContextInterface>(initialState);

export const AuthProvider = ({ children }: any) => {
  const router = useRouter();
  const theme = useTheme();
  const { user } = useAppSelector((state) => state.user);
  const [auth, setAuth] = useState<'loading' | 'auth' | 'unauth'>('loading');
  const [sellerAuth, setSellerAuth] = useState<'loading' | 'auth' | 'unauth'>(
    'loading'
  );

  // console.log({
  //   user: user,
  //   auth: auth,
  //   sellerAuth: sellerAuth,
  // });

  const storeUser = useMemo(() => {
    if (!user) return null;

    if (user.store_id) {
      return 'admin';
    }

    if (user.agentStoreDetails?.cust_id) {
      return 'agent';
    }
    return null;
  }, [user]);

  const redirectMethod = useCallback(() => {
    let routerQuery =
      router.query.redirect == '0'
        ? {
            login: 1,
          }
        : {
            login: 1,
            redirectionUrl: encodeURIComponent(window.location.href),
          };

    // console.log('router', routerQuery);

    router.push({
      ...router,
      pathname: '/',
      query: {
        ...router.query,
        ...routerQuery,
      },
    });
  }, [router]);

  const checkSellerAuthStatus = useCallback(async () => {
    try {
      setSellerAuth('loading');
      await getSellerAuthService();
      setSellerAuth('auth');
    } catch (error) {
      setSellerAuth('unauth');
    }
  }, []);

  const checkUserAuthStatus = useCallback(async () => {
    try {
      setAuth('loading');
      if (user) {
        setAuth('auth');
        if (user?.storeDetails) {
          await checkSellerAuthStatus();
        } else if (router.pathname.includes('/user/selling/')) {
          router.replace('/user/profile');
        }
      } else {
        setAuth('unauth');
      }
    } catch (error) {
      setAuth('unauth');
    }
  }, [checkSellerAuthStatus, user]);

  useEffect(() => {
    if (user !== undefined) {
      checkUserAuthStatus();
    }
  }, [user, checkUserAuthStatus, checkSellerAuthStatus]);

  if (
    (auth === 'loading' || (sellerAuth === 'loading' && user?.store_id)) &&
    user !== null &&
    router.pathname.includes('/user')
  ) {
    if (auth === 'unauth' || sellerAuth === 'unauth') {
      redirectMethod();
    }

    return (
      <LoadingBg>
        <Image
          src={`/images/fetching-product-loader-${
            theme.mode === 'dark' ? 'dark' : 'light'
          }.gif`}
          width="40px"
          height="28px"
          alt="fetching gif"
        />
      </LoadingBg>
    );
  }
  if (
    (auth === 'unauth' || sellerAuth === 'unauth' || user === null) &&
    router.pathname.includes('/user')
  ) {
    redirectMethod();
    return (
      <LoadingBg>
        <Image
          src={`/images/fetching-product-loader-${
            theme.mode === 'dark' ? 'dark' : 'light'
          }.gif`}
          width="40px"
          height="28px"
          alt="fetching gif"
        />
      </LoadingBg>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        sellerAuth,
        checkUserAuthStatus,
        checkSellerAuthStatus,
        storeUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const LoadingBg = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.palette.background.bg1};
  display: flex;
  justify-content: center;
  align-items: center;
`;
