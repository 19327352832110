import axios from 'axios';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useState } from 'react';
import { apiConstants } from '../constants/apiConstants';
import { SnackbarContext } from './SnackbarContext';

interface NewsletterContextI {
  loading: boolean;
  handleNewsletterCLick: (
    _email: string,
    _action: 'subscribe' | 'unsubscribe'
  ) => Promise<boolean>;
}

export const NewsletterContext = createContext<NewsletterContextI>({
  loading: true,
  handleNewsletterCLick: (
    _email: string,
    _action: 'subscribe' | 'unsubscribe'
  ) => Promise.resolve(false),
});

export const useNewsletter = () => useContext(NewsletterContext);

export const NewsletterProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const handleNewsletterCLick = useCallback(
    async (email: string, action: 'subscribe' | 'unsubscribe') => {
      try {
        const payload: { email: string } = {
          email: email,
        };

        const { status, data } = await axios.post(
          apiConstants.server + `/public/newsletter/${action}`,
          payload,
          {
            withCredentials: true,
          }
        );
        if (status === 200) {
          dispatchSnackbar({
            type: 'success',
            payload: data?.message,
          });
        }
        return true;
      } catch (err: any) {
        dispatchSnackbar({
          type: 'failure',
          payload:
            err?.response?.data?.message || 'Something went wrong,Try Again',
        });
        return false;
      }
    },
    [router]
  );

  return (
    <NewsletterContext.Provider value={{ loading, handleNewsletterCLick }}>
      {children}
    </NewsletterContext.Provider>
  );
};
