import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const initABTests = () => {};

const getTestValues = (key: string) => {
  try {
    const abTestsTemp = cookies.get('abTests');
    const abTests =
      typeof abTestsTemp == 'string' ? JSON.parse(abTestsTemp) : abTestsTemp;

    if (abTests) {
      return abTests[key];
    }
  } catch (err: any) {
    console.log('cookie err', err);
  }

  return '';
};

export const getMobilePdpButtonsOrder = () => {
  const order = getTestValues('abTest2');
  switch (order) {
    case '1':
      return 'reverse';
    case '2':
      return 'same';
    default:
      return 'same';
  }
};

export const getPdpSidebarFlow = () => {
  const getPdpSidebar = getTestValues('abTest3');
  switch (getPdpSidebar) {
    case '1':
      return 'sticky';
    case '2':
      return 'relative';
    default:
      return 'sticky';
  }
};

export const getPDPPrimarySellerCardButtonFlow = () => {
  const getPDPPrimarySellerCardButton = getTestValues('abTest4');
  switch (getPDPPrimarySellerCardButton) {
    case '1':
      return 'column';
    case '2':
      return 'column-reverse';
    default:
      return 'column';
  }
};

export const showMysteryBox = () => {
  const showMysBox = getTestValues('abTest5');
  switch (showMysBox) {
    case '1':
      return true;
    case '2':
      return false;
    default:
      return false;
  }
};

function chooseRandomFromArray<T>(arr: Array<T>): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export const getProductRecommendationType = () => {
  const getProductRecommendationType = getTestValues('abTest6');
  switch (getProductRecommendationType) {
    case '1':
      return 'recombee';
    case '2':
      return 'driffle';
    default:
      return 'driffle';
  }
};

export const showRecombeeProductRecommendations = () => {
  const showProductRecommendations = getTestValues('abTest7');
  switch (showProductRecommendations) {
    case '1':
      return true;
    case '2':
      return false;
    default:
      return true;
  }
};

export const getHomePageLayoutType = () => {
  const homeLayoutType = getTestValues('abTest8');
  switch (homeLayoutType) {
    case '1':
      return '1';
    case '2':
      return '2';
    default:
      return '1';
  }
};

export const getSearchABType = () => {
  const searchABType = getTestValues('abTest9');
  switch (searchABType) {
    case '1':
      return '1';
    case '2':
      return '1'; // changed on 4th march 2024
    default:
      return '1';
  }
};

export const getFBTPromoType = () => {
  const promoValue = getTestValues('abTest10');

  switch (promoValue) {
    case '1':
      return '1';
    case '2':
      return '2';
    default:
      return '1';
  }
};

export const getPaymentABTestMethods = () => {
  const paymentABTestMethods = getTestValues('abTest11');
  switch (paymentABTestMethods) {
    case '1':
      return '1';
    case '2':
      return '2';
    default:
      return '2';
  }
};

export const getSubscriptionABTestMethods = () => {
  const subscriptionABTestMethods = getTestValues('abTest12');
  switch (subscriptionABTestMethods) {
    case '1':
      return 'yes';
    case '2':
      return 'no';
    default:
      return 'no';
  }
};
