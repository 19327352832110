import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ThemeState {
  theme: any;
}

type ThemeType = 'light' | 'dark';

// Define the initial state using that type
const initialState: ThemeState = {
  theme: 'dark',
};

export const themeSlice = createSlice({
  name: 'Theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === 'dark' ? 'light' : 'dark';
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleTheme, setTheme } = themeSlice.actions;

export default themeSlice.reducer;
