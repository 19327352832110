import styled from 'styled-components';

export const NoJSMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  min-height: 60px;
  padding: 8px;
`;

export const NoJSMessageText = styled.div`
  font: 1rem/1.5rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
`;
