import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';

import { useMemo } from 'react';
import globalReducer from './globalSlice';
import listerReducer from './listerSlice';
import modalReducer from './modalSlice';
import themeReducer from './themeSlice';
import userReducer from './userSlice';

let store: any;

export const initialiseStore = (preloadedState: any) => {
  let _store = store ?? createStore(preloadedState);

  if (preloadedState && store) {
    _store = createStore({
      ...store?.getState(),
      global: {
        ...(store?.getState()?.global ?? preloadedState.global),
        locationDetails: preloadedState.global.locationDetails,
        geoLocation: preloadedState.global.geoLocation,
        deviceId: preloadedState.global.deviceId,
      },
      user: {
        ...(store?.getState()?.user ?? preloadedState.user),
        loginStatus: preloadedState.user.loginStatus,
      },
      cart: {
        ...(store?.getState()?.cart ?? preloadedState.cart),
      },
      lister: {
        ...(store?.getState()?.lister ?? preloadedState.lister),
      },
    });
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

const createStore = (preloadedState: any) => {
  return configureStore({
    reducer: {
      modal: modalReducer,
      user: userReducer,
      cart: cartReducer,
      global: globalReducer,
      lister: listerReducer,
      theme: themeReducer,
    },
    preloadedState,
  });
};

export const useStore = (initialState: any) => {
  const store = useMemo(() => initialiseStore(initialState), [initialState]);
  return store;
};
