// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

// const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  // dsn: SENTRY_DSN || 'https://c3b85991f6f94441ac75e26a5bbe3bf6@o1159965.ingest.sentry.io/6244287',
  environment: process.env.NEXT_PUBLIC_APP_ENV || process.env.NODE_ENV,
  dsn: 'https://c3b85991f6f94441ac75e26a5bbe3bf6@o1159965.ingest.sentry.io/6244287',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
