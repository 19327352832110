import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CountrySearchI } from '../esims/interfaces';
import { getSearchResults } from '../esims/services/search';
import {
  getSessionStorageExp,
  saveSessionStorageExp,
} from '../utils/sessionStorageUtils';

interface ESimContextI {
  isESim: boolean;
  loading: boolean;
  countrySkuList: Array<CountrySearchI>;
  regionSkuList: Array<CountrySearchI>;
}

const ESimContext = createContext<ESimContextI>({
  isESim: false,
  loading: true,
  regionSkuList: [],
  countrySkuList: [],
});

export const useESim = () => {
  return useContext(ESimContext);
};

export const ESimProvider = ({ children }: any) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const [countrySkuList, setCountrySkuList] = useState<Array<CountrySearchI>>(
    []
  );
  const [regionSkuList, setRegionSkuList] = useState<Array<CountrySearchI>>([]);

  const handleCleanSearchData = (bundles: Array<CountrySearchI>) => {
    const regions = bundles.filter((cur) => cur.type === 'regional');
    const countries = bundles.filter((cur) => cur.type === 'country');

    setCountrySkuList(countries);
    setRegionSkuList(regions);
  };

  const handleGetSearchResults = useCallback(async () => {
    setLoading(true);
    try {
      const sessionData = getSessionStorageExp('esim-bundles');
      if (!sessionData) {
        const data = await getSearchResults();
        saveSessionStorageExp('esim-bundles', data.countryList, 60);
        handleCleanSearchData(data.countryList);
      } else {
        handleCleanSearchData(sessionData);
      }
    } catch (e) {}
    setLoading(false);
  }, []);

  useEffect(() => {
    if (router.asPath.split('?')[0].split('/')[1] === 'esim') {
      handleGetSearchResults();
    }
  }, [handleGetSearchResults, router]);

  const value = useMemo(() => {
    return {
      countrySkuList,
      regionSkuList,
      loading,
      isESim: router.asPath.includes('/esim'),
    };
  }, [countrySkuList, regionSkuList, loading, router]);

  return <ESimContext.Provider value={value}>{children}</ESimContext.Provider>;
};
