/* eslint-disable react/display-name */
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { initABTests } from '../utils/abTest';
import {
  deleteLocalStorage,
  getLocalStorageExp,
  saveLocalStorageExp,
} from '../utils/localStorageUtils';

const cookies = new Cookies();

const pageLoad = (WrappedComponent: any) => (props: any) => {
  const router = useRouter();
  // cookies.set('MUID', 'sdf');
  // cookies.remove('MUID', {
  //   domain: 'localhost',
  // });
  if (typeof window !== 'undefined') {
    cookies.remove('currency', {
      domain: '.driffle.com',
    });
    cookies.remove('currency', {
      domain: '.dev.driffle.com',
    });
    /**
     * generating a unique user-id for every device
     * this will be used to give GA a user id.
     */
    try {
      const uniqueUserId = getLocalStorageExp('nan_gen_uni');
      if (!uniqueUserId) {
        const data = {
          userId: nanoid(20),
        };
        saveLocalStorageExp('nan_gen_uni', data, 100000);
      }
    } catch (e) {}
  }
  if (
    typeof window !== 'undefined' &&
    router.isReady &&
    router.query.currency &&
    typeof router.query.currency === 'string' &&
    router.query.currency !== ''
  ) {
    // cookies.set('currency', router.query.currency);
    deleteLocalStorage('currency');

    /**
     * ak-TODO
     */
    // cookies.set('currency', router.query.currency);
  }

  if (typeof window !== 'undefined' && router.isReady) {
    initABTests();
  }

  return <WrappedComponent {...props} />;
};

export default pageLoad;
