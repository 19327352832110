import axios from 'axios';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { apiConstants } from '../constants/apiConstants';
import { getRegionFromRegionId } from '../helper/helper';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { openLoginModalRedux } from '../redux/modalSlice';
import { saveEventV3 } from '../utils/eventTracking';
import { WE_USER_EVENT_NOTIFY_WHEN_IN_STOCK } from '../utils/we';
import { useAnalytics } from './AnalyticsProvider';
import { SnackbarContext } from './SnackbarContext';

export interface NotifyMeProductInterface {
  productId: number;
  productSku: number;
  title: string;
  slug: string;
  image: string;
  cover_image: string;
  platform: string;
  ageRating: null | number;
  relaseDate: string;
  price: string;
  maxPrice: string;
  regionId: string | number;
}

export type NotifyMeProductsContextI = {
  notifyProducts: NotifyMeProductInterface[] | null;
  loading: boolean;
  handleNotifyProductClick: (
    _product: PropsProductI,
    _action: 'add' | 'remove'
  ) => Promise<boolean>;
};

interface PropsProductI {
  productId: number;
  title: string;
  slug: string;
  image: string;
  platform: string | null;
  price: string | number | null;
  regionId: string | number | null;
}

export const NotifyMeProductsContext = createContext<NotifyMeProductsContextI>({
  notifyProducts: null,
  loading: true,
  handleNotifyProductClick: (
    _product: PropsProductI,
    _action: 'add' | 'remove'
  ) => Promise.resolve(false),
});

export const useNotifyMeProducts = () => useContext(NotifyMeProductsContext);

export const NotifyMeProductsProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { user } = useAppSelector((state) => state.user);
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { cleverTap } = useAnalytics();

  const [products, setProducts] = useState<NotifyMeProductInterface[] | null>(
    null
  );

  const fetchNotifyMeProducts = useCallback(async () => {
    if (user) {
      try {
        setLoading(true);
        const { status, data } = await axios.get(
          apiConstants.server + '/private/notify-me',
          {
            withCredentials: true,
          }
        );

        if (status === 200) {
          setProducts(data.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setProducts(null);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchNotifyMeProducts();
  }, [fetchNotifyMeProducts]);

  const handleNotifyProductClick = useCallback(
    async (product: PropsProductI, action: 'add' | 'remove') => {
      try {
        const payload = {
          pid: product.productId,
        };
        if (!user || !user.email) {
          dispatch(openLoginModalRedux());
          return false;
        }
        const { status, data } = await axios.post(
          apiConstants.server + `/private/notify-me/${action}`,
          payload,
          {
            withCredentials: true,
          }
        );
        if (status === 200) {
          if (action === 'add') {
            setProducts((prev) => {
              if (prev) {
                return [...prev, data.data];
              }
              return [data.data];
            });
            saveEventV3({
              category: 'primary_seller_box',
              action: 'click',
              label: 'notify_me_when_in_stock',
              properties: product.slug,
              value: [product.slug],
              from: router,
            });
            WE_USER_EVENT_NOTIFY_WHEN_IN_STOCK(
              {
                productImage: product.image,
                productPlatform: product.platform ?? '-',
                productRegion: getRegionFromRegionId(
                  String(product.regionId ?? '-')
                ),
                productSlug: product.slug,
                productTitle: product.title,
              },
              cleverTap
            );

            // WE_ASK_USER_WEB_PUSH_PERMISSION(
            //   {
            //     title: instant_notifications_msg,
            //     description: sign_up_to_get_notified_about_special_deals_msg,
            //     router,
            //   },
            //   cleverTap
            // );
          } else {
            setProducts((prev) => {
              if (prev) {
                return prev.filter((p) => p.productId !== product.productId);
              }
              return null;
            });
          }
        }
        return true;
      } catch (err: any) {
        dispatchSnackbar({
          type: 'failure',
          payload: err?.response?.data?.msg,
        });
        return false;
      }
    },
    [router, user, cleverTap]
  );

  return (
    <NotifyMeProductsContext.Provider
      value={{ notifyProducts: products, loading, handleNotifyProductClick }}
    >
      {children}
    </NotifyMeProductsContext.Provider>
  );
};
