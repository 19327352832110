import axios from 'axios';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ProductSectionCardInterface } from '../Interface/ProductCardInterface';
import { apiConstants } from '../constants/apiConstants';

export type TrendingProductsContextI = {
  trendingProducts: ProductSectionCardInterface[] | null;
  loading: boolean;
  fetchTrendingProducts: () => Promise<void>;
};

export const TrendingProductsContext = createContext<TrendingProductsContextI>({
  trendingProducts: null,
  loading: true,
  fetchTrendingProducts: async () => {},
});

export const useTrendingProducts = () => useContext(TrendingProductsContext);

export const TrendingProductsProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [products, setProducts] = useState<
    ProductSectionCardInterface[] | null
  >(null);

  const fetchTrendingProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(apiConstants.search + '/search', {
        withCredentials: true,
      });
      setLoading(false);
      if (response.status === 200) {
        setProducts(response.data.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const value = useMemo(() => {
    return {
      trendingProducts: products,
      loading,
      fetchTrendingProducts,
    };
  }, [products, loading, fetchTrendingProducts]);

  return (
    <TrendingProductsContext.Provider value={value}>
      {children}
    </TrendingProductsContext.Provider>
  );
};
