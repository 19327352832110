/* eslint-disable @next/next/no-page-custom-font */
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useCallback, useEffect } from 'react';
import { Provider } from 'react-redux';
import pageLoad from '../HOC/pageLoad';
import BaseLayout from '../Layout/BaseLayout';
import { SnackbarProvider } from '../contexts/SnackbarContext';
import '../styles/globals.css';
import '../styles/globals.scss';
import '../styles/nprogress.css';

import Script from 'next/script';
import { gtmConstants } from '../constants/gtmConstants';
import {
  NSURE_PRODUCTION_APP_ID,
  NSURE_SANDBOX_APP_ID,
} from '../constants/nsure';
import { AnalyticsProvider } from '../contexts/AnalyticsProvider';
import { ESimProvider } from '../contexts/ESimContext';
import { LocalizationProvider } from '../contexts/LocalizationContext';
import { NewsletterProvider } from '../contexts/NewsletterContext';
import { NotifyMeProductsProvider } from '../contexts/NotifyMeProductsContext';
import { PageLoaderProvider } from '../contexts/PageLoaderContext';
import { AppThemeProvider } from '../contexts/ThemeContext';
import { TrendingProductsProvider } from '../contexts/TrendingProductsContext';
import { useStore } from '../redux/newStore';
import { AuthProvider } from '../user/context/AuthContext';
import { saveEventV3 } from '../utils/eventTracking';

// if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

function MyApp({ Component, pageProps }: any) {
  const reduxStore = useStore(pageProps.reduxInitialState);

  Router.events.on('routeChangeStart', (url) => {
    NProgress.start();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });

  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });

  const router = useRouter();
  const { locale } = router;

  const pwaInstallationEvent = useCallback(() => {
    saveEventV3({
      category: 'pwa',
      action: 'click',
      label: 'pwa_installed',
      properties: '',
      value: [],
      from: router,
    });
  }, [router]);

  useEffect(() => {
    window.addEventListener('appinstalled', pwaInstallationEvent);
    return () => {
      window.removeEventListener('appinstalled', pwaInstallationEvent);
    };
  }, [pwaInstallationEvent]);

  useEffect(() => {
    try {
      caches
        .delete('cross-origin-image-cache')
        .then(function (isDeleted) {
          if (isDeleted) {
            console.log('Cache successfully deleted!');
          }
        })
        .catch((e) => {});
    } catch (err: any) {
      console.log(err);
    }
  }, []);

  return (
    <>
      {router.asPath.includes('/esim') ? null : (
        <Script
          id="termly"
          strategy="afterInteractive"
          type="text/javascript"
          src="https://app.termly.io/resource-blocker/e8348f34-2290-4567-bf08-353586ec9bc0?autoBlock=on"
        />
      )}

      <Script
        strategy="afterInteractive"
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `(
                function(w,d,s,l,i){
                  w[l]=w[l]||[];
                  w[l].push({
                    'gtm.start':
                    new Date().getTime(),
                    event:'gtm.js'
                  });
                  var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl=l!='dataLayer'?'&l='+l:'';
                  j.async=true;
                  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                  f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${gtmConstants.gtmId}');`,
        }}
      />
      {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
        <>
          <Script
            id="nsure-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `try{
                          window.nSureAsyncInit = function(deviceId) {
                            window.nSureSDK.init('${NSURE_PRODUCTION_APP_ID}');
                          }
                      }catch(e){}`,
            }}
          />
          <Script
            src="https://sdk.nsureapi.com/sdk.js"
            strategy="afterInteractive"
          />
        </>
      ) : (
        <>
          <Script
            id="nsure-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `try{
                          window.nSureAsyncInit = function(deviceId) {
                            window.nSureSDK.init('${NSURE_SANDBOX_APP_ID}');
                          }
                      }catch(e){}`,
            }}
          />
          <Script
            src="https://sdk.nsureapi.com/sdk-sandbox.js"
            strategy="afterInteractive"
          />
        </>
      )}

      {(process.env.NEXT_PUBLIC_APP_ENV === 'production' ||
        process.env.NEXT_PUBLIC_APP_ENV === 'staging') && (
        // {
        <Script
          id="service-worker"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          if ("serviceWorker" in navigator) {
            // Use the window load event to keep the page load performant
            try{
                window.addEventListener("load", () => {
                navigator.serviceWorker.register("/sw.js").then(function(registration) {
                  //  console.log("Service worker registered: ", registration);
                }).catch(()=>{})
              })
            }catch(e){
            }
          }
          `,
          }}
        />
      )}
      {/* } */}

      {/* Trustpilot script */}
      <Script
        id="trustpilot"
        strategy="afterInteractive"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />

      <Script
        src="https://accounts.google.com/gsi/client"
        strategy="afterInteractive"
      />

      {/* <!-- Start of Jewel ML Embed Code --> */}
      {/* <Script
        strategy="afterInteractive"
        id="jewel-ml-render"
        dangerouslySetInnerHTML={{
          __html: `
            var d = document || window.document; var s = 'script'; var s0 = d.getElementsByTagName(s)[ 0 ]; var ps = [
              '//rerender.jewelml.io/r/d/67052b4875c930a15d8e2867/r.js' ];
            ps.forEach(function (p) { var j = d.createElement(s); j.defer = true; j.type = 'text/javascript'; j.src = p; s0.parentNode.insertBefore(j, s0); });
          `,
        }}
      /> */}
      {/* <!-- End of Jewel ML Embed Code --> */}

      {/* <!-- Start of Jewel ML Embed Code --> */}
      {/* <Script
        id="jewel-ml-event"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            var d = document || window.document; var s = 'script'; var s0 = d.getElementsByTagName(s)[ 0 ]; var ps = [
            '//ga4events.jewelml.io/v/67052b4875c930a15d8e2867/js' ];
            ps.forEach(function (p) { var j = d.createElement(s); j.async = true; j.type = 'text/javascript'; j.src = p; s0.parentNode.insertBefore(j, s0); });
          `,
        }}
      /> */}
      {/* <!-- End of Jewel ML Embed Code --> */}

      {/* <!-- Facebook Pixel Code --> */}
      {/* {(cookies.get('drif_acq') === 'facebook-ads' ||
          router.query.utm_source === 'facebook-ads') && ( */}
      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '211906725136325');
            fbq('track', 'PageView');
            `,
        }}
      />
      {/* )} */}
      {/* {(cookies.get('drif_acq') === 'facebook-ads' ||
          router.query.utm_source === 'facebook-ads') && ( */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <img 
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=211906725136325&ev=PageView&noscript=1"
            />
          `,
        }}
      />
      {/* )} */}
      {/* <!-- End Facebook Pixel Code --> */}

      {/* <!-- Snap Pixel Code --> */}
      <Script
        id="snap-pixel"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
             try {
              (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s="script";r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                'https://sc-static.net/scevent.min.js');
    
                snaptr('init', '947e2daa-f0ff-4f0c-94e8-261b514a5c8c');
    
                snaptr('track', 'PAGE_VIEW');
             } catch (error) {
                console.log(error);
             }
            `,
        }}
      />
      {/* <!-- End Snap Pixel Code --> */}

      {/* <Script src="./js/admitAd.js" /> */}

      {/* {(cookies.get('drif_acq') === 'driffleaff' ||
        router.query.utm_source === 'driffleaff') && ( */}
      {/* <Script
        type="text/javascript"
        id="pap_x2s6df8d"
        src="https://driffle.postaffiliatepro.com/scripts/gqwn1j4d2"
      /> */}
      {/* )} */}

      {/* {(cookies.get('drif_acq') === 'driffleaff' ||
        router.query.utm_source === 'driffleaff') && ( */}
      {/* <Script
        type="text/javascript"
        id="pap_tracker"
        dangerouslySetInnerHTML={{
          __html: `
            setTimeout(function () {
              try {
                if (window.PostAffTracker) {
                  PostAffTracker.setAccountId('default1');
                  PostAffTracker.track();
                } else {
                  //console.log('else');
                }
              } catch (err) {
                console.log(err);
              }
            }, 3000);
            `,
        }}
      /> */}
      {/* )} */}

      <Head>
        <title>Driffle</title>
        {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
          <>
            <link rel="dns-prefetch" href="https://api.driffle.com" />
            <link rel="preconnect" href="https://api.driffle.com" />
          </>
        ) : (
          <>
            <link rel="dns-prefetch" href="https://api.69gamers.com" />
            <link rel="preconnect" href="https://api.69gamers.com" />
          </>
        )}

        <link rel="preconnect" href="https://assets.driffle.com" />
        <link rel="preconnect" href="https://static.driffle.com" />

        <link rel="dns-prefetch" href="https://assets.driffle.com" />
        <link rel="dns-prefetch" href="https://static.driffle.com" />

        <link rel="dns-prefetch" href="https://app.termly.io" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
        <link rel="dns-prefetch" href="https://www.google-analytics.com" />
        <link rel="dns-prefetch" href="//widget.trustpilot.com" />
        <link rel="dns-prefetch" href="https://www.clarity.ms" />

        <meta name="google-adsense-account" content="ca-pub-2614172121091543" />

        {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
          <>
            <link
              rel="preload"
              href="https://assets.driffle.com/fonts/Onest/static/Onest-Medium.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="https://assets.driffle.com/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="https://assets.driffle.com/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
          </>
        ) : (
          <>
            <link
              rel="preload"
              href="/fonts/Onest/static/Onest-Medium.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
            <link
              rel="preload"
              href="/fonts/Onest/static/Onest-Bold.ttf"
              as="font"
              crossOrigin=""
              type="font/ttf"
            />
          </>
        )}

        {/* {languageList.map((val: any, index: number) => (
          <link
            rel="alternate"
            type="text/html"
            hrefLang={val.locale}
            title={val.name}
            href={
              apiConstants.frontend +
              (val.locale === 'en' ? '' : `/${val.locale}`) +
              router.asPath
            }
            key={index}
          />
        ))} */}

        <link rel="shortcut icon" href="/site-assets/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/site-assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/site-assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/site-assets/favicon-16x16.png"
        />
        <link rel="preload" as="image" href="/logo/driffle-logo-white.svg" />

        {pageProps.reduxInitialState?.user?.subscription === 'active' && (
          <link
            rel="preload"
            as="image"
            href="/logo/driffle-plus-dark-logo.svg"
          />
        )}

        <link rel="manifest" href="/manifest.json"></link>

        <script
          id="json-ld-product"
          defer
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@graph': [
                {
                  '@context': 'https://schema.org/',
                  '@type': 'Organization',
                  name: 'Driffle Inc',
                  url: 'https://driffle.com/',
                  logo: 'https://driffle.com/logo/driffle-logo-white.svg',
                  description:
                    'Driffle is a digital goods marketplace specializing in digital gaming products, including games, gift cards, DLCs, and more. We offer a convenient and diverse selection, from the newest releases to timeless classics, all in one place.',
                  brand: {
                    '@type': 'Brand',
                    name: 'Driffle',
                  },
                  contactPoint: {
                    '@type': 'contactPoint',
                    contactType: 'customer service',
                    url: 'https://driffle.com/support',
                    email: 'support@driffle.com',
                    availableLanguage: [
                      'English',
                      'Spanish',
                      'German',
                      'Italian',
                      'Dutch',
                      'French',
                      'Hungarian',
                      'Polish',
                      'Danish',
                      'Portuguese',
                      'Finnish',
                      'Swedish',
                      'Brazilian',
                      'Indonesian',
                      'Thai',
                      'Vietnamese',
                      'Czech',
                      'Greek',
                      'Filipino',
                      'Romanian',
                      'Russian',
                      'Turkish',
                      'Lithuanian',
                      'Simplified Chinese',
                    ],
                  },
                  sameAs: [
                    'https://www.linkedin.com/company/driffle/',
                    'https://www.facebook.com/drifflecom/',
                    'https://www.instagram.com/drifflecom/',
                    'https://discord.gg/MryXrnwZvz',
                    'https://www.youtube.com/@drifflecom',
                  ],
                },
              ],
            }),
          }}
        />

        {/* TicTok Pixel Code */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
            
              ttq.load('CNT9MHBC77UBM1BSGRP0');
              ttq.page();
            }(window, document, 'ttq');
            `,
          }}
        /> */}
        {/* <!-- End TikTok Pixel Code --> */}
      </Head>

      <LocalizationProvider
        locale={(pageProps as { locale?: string })?.locale || locale || 'en'}
        localeData={(pageProps as { localeData?: any })?.localeData}
      >
        <Provider store={reduxStore}>
          <AppThemeProvider>
            <SnackbarProvider>
              <PageLoaderProvider>
                <ESimProvider>
                  <TrendingProductsProvider>
                    <AnalyticsProvider>
                      <BaseLayout>
                        <AuthProvider>
                          <NotifyMeProductsProvider>
                            <NewsletterProvider>
                              <Component {...pageProps} />
                            </NewsletterProvider>
                          </NotifyMeProductsProvider>
                        </AuthProvider>
                      </BaseLayout>
                    </AnalyticsProvider>
                  </TrendingProductsProvider>
                </ESimProvider>
              </PageLoaderProvider>
            </SnackbarProvider>
          </AppThemeProvider>
        </Provider>
      </LocalizationProvider>

      {/* <!-- BEGIN AWIN MASTER TAG - DO NOT MODIFY --> */}
      <Script
        src="https://www.dwin1.com/52015.js"
        type="text/javascript"
        strategy="afterInteractive"
      />
      {/* <!-- END AWIN MASTER TAG --> */}

      {/* <!-- BEGIN CJ TRACKING CODE - DO NOT MODIFY --> */}
      {/* <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function(a,b,c,d){
            a='//www.mczbf.com/tags/598241255716/tag.js';
            b=document;c='script';d=b.createElement(c);d.src=a;
            d.type='text/java'+c;d.async=true;
            d.id='cjapitag';
            a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
            })();
          `,
        }}
      ></S> */}
      {/* <!-- END CJ TRACKING CODE --> */}
    </>
  );
}
export default pageLoad(MyApp);
