import { NextRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { getCookieDomain } from '../helper/helper';
import { saveEventV3 } from './eventTracking';

interface ITMConfig {
  medium?: string;
  source?: string;
  campaign: string;
  content?: string;
  direct?: boolean;
  router?: NextRouter;
}

// Create a cookie instance globally
const cookies = new Cookies();

// Set ITM values in a cookie
export const setItmCookie = (config: ITMConfig): void => {
  try {
    const { medium, source, campaign, content, direct, router } = config;

    let itm_cookie_json = {
      itm_source: '',
      itm_medium: '',
      itm_campaign: '',
      itm_content: '',
    };

    if (source) itm_cookie_json.itm_source = source;
    if (medium) itm_cookie_json.itm_medium = medium;
    if (campaign) itm_cookie_json.itm_campaign = campaign;
    if (content) itm_cookie_json.itm_content = content;

    if (direct && router) {
      // if (isSourcePresent) return;
      itm_cookie_json = getItmJsonValues(router, campaign, content || '');
    }

    cookies.set('itm', JSON.stringify(itm_cookie_json), {
      expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      path: '/',
      domain: getCookieDomain(),
      secure: true,
      sameSite: 'none',
    });
  } catch (err) {
    console.log('set-itm', err);
  }
};

// Add a route to the itmRoutes cookie
export const setItmRoutes = (route: string): void => {
  try {
    let itmRoutes = cookies.get('user_journey') || '';

    if (itmRoutes !== route) {
      itmRoutes += (itmRoutes ? ',' : '') + route;
    }

    cookies.set('user_journey', itmRoutes, {
      expires: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      path: '/',
      domain: getCookieDomain(),
      secure: true,
      sameSite: 'none',
    });
  } catch (err) {
    console.log('set-itm-routes', err);
  }
};

// Save the itm cookie values to an event tracking service
export const saveItmCookie = (router: NextRouter, remove: boolean): void => {
  try {
    const itmRoutes = cookies.get('user_journey');
    const itmValues = cookies.get('itm');

    if (itmValues && itmRoutes) {
      saveEventV3({
        action: 'click',
        category: 'itm',
        label: 'save-itm-values',
        value: [],
        jsonData: {
          itm_values: JSON.parse(itmValues),
          itm_routes: itmRoutes,
        },
        properties: '',
        from: router,
      });

      if (remove) {
        cookies.remove('user_journey');
        cookies.remove('itm');
      }
    }
  } catch (err) {
    console.log('save-itm', err);
  }
};

// Get the ITM JSON values based on the current route
export const getItmJsonValues = (
  router: NextRouter,
  campaign: string,
  content: string
): any => {
  try {
    const query = router.query;
    let itm_source = '';
    let itm_medium = '';
    let itm_campaign = campaign;
    let itm_content = content;

    switch (router.pathname) {
      // Store pages
      case '/store':
        itm_source = 'store';
        itm_medium = 'product-card';
        break;
      case '/store/[type]':
        itm_source = router.query.type as string;
        itm_medium = 'product-card';
        break;
      case '/store/[type]/[value]':
        itm_source = `${router.query.type} ${router.query.value}`;
        itm_medium = 'product-card';
        break;
      case '/store/collection/[type]':
        itm_source = `${router.query.type}`;
        itm_medium = 'product-card';
        break;
      case '/store/collection/deals-of-the-day':
        itm_source = 'deals-of-the-day';
        itm_medium = 'product-card';
        break;

      // Sale pages
      case '/store/collection/sale/[type]':
        itm_source = `${router.query.type}`;
        itm_medium = 'product-card';

        break;

      case '/sale/[productType]/[platform]':
        itm_source = `${router.query.productType}-${router.query.platform}`;
        itm_medium = 'product-card';
        break;

      case '/sale/giftcard':
        itm_source = 'giftcard';
        itm_medium = 'product-card';
        break;

      //vendor page store
      case '/vendor/[slug]':
        itm_source = `vendor-${String(router.query.slug)}-store`;
        itm_medium = 'product-card';
        break;

      default:
        break;
    }

    return {
      itm_source,
      itm_medium,
      itm_campaign,
      itm_content,
    };
  } catch (err) {
    console.log('get-itm-json', err);
    throw err;
  }
};
