import React, { useEffect, useRef } from 'react';

/*
  This hook is not called when component renders for the first, but then onwards
  if the dependency changes then the component re-renders
*/
const useDidMountEffect = (func: () => void, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {   
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
