type ApiConstantsType = {
  ssrServer: {
    frontend:
      | 'https://driffle.com'
      | 'https://web.stage.driffle.com'
      | 'https://web.dev.driffle.com'
      | 'http://localhost:4001'
      | 'https://driffle.dev';
    server:
      | 'https://api.prod.[--REGION--].driffle.com'
      | 'https://api.[--REGION--].driffle.com'
      // | 'https://api.dev.driffle.com'
      | 'http://localhost:3000';

    sellerServer:
      | 'https://seller.prod.[--REGION--].driffle.com/seller'
      | 'https://api.[--REGION--].driffle.com/seller'
      | 'https://seller.[--REGION--].driffle.com/seller'
      | 'http://localhost:3001/seller';
    frontendEvent:
      | 'https://events.driffle.com'
      | 'https://events.dev.driffle.com'
      | 'https://events.driffle.dev'
      | 'http://localhost:3004';

    search:
      | 'https://search.prod.[--REGION--].driffle.com'
      | 'https://search.[--REGION--].driffle.com'
      | 'http://localhost:3005';
    support:
      | 'http://support.central.driffle.com'
      | 'https://support.central.driffle.com';
    career: 'https://careers.driffle.com/wp-json/jobs/v1';
    careerLocal: 'http://localhost/myspace/jobs/wp-json/jobs/v1';
  };

  frontend:
    | 'https://driffle.com'
    | 'https://web.stage.driffle.com'
    | 'https://web.dev.driffle.com'
    | 'http://localhost:4001'
    | 'https://driffle.dev';
  server:
    | 'https://api.driffle.com'
    | 'https://api.driffle.dev'
    | 'https://api.dev.driffle.com'
    | 'http://localhost:3000';

  sellerServer:
    | 'https://seller.driffle.com/seller'
    | 'https://api.driffle.dev/seller'
    | 'https://seller.driffle.dev/seller'
    | 'https://seller.dev.driffle.com/seller'
    | 'http://localhost:3001/seller';
  frontendEvent:
    | 'https://events.driffle.com'
    | 'https://events.dev.driffle.com'
    | 'https://events.driffle.dev'
    | 'http://localhost:3004';

  search:
    | 'https://search.driffle.com'
    | 'https://search.dev.driffle.com'
    | 'https://search.driffle.dev'
    | 'http://localhost:3005';
  support:
    | 'http://support.central.driffle.com'
    | 'https://support.central.driffle.com';
  career: 'https://careers.driffle.com/wp-json/jobs/v1';
  careerLocal: 'http://localhost/myspace/jobs/wp-json/jobs/v1';
};

export const apiConstants: ApiConstantsType = {
  ssrServer: {
    frontend:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? 'https://driffle.com'
        : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        ? 'https://driffle.dev'
        : process.env.NEXT_PUBLIC_APP_ENV === 'development'
        ? 'https://web.dev.driffle.com'
        : 'http://localhost:4001',
    server:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? `https://api.prod.[--REGION--].driffle.com`
        : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        ? `https://api.[--REGION--].driffle.com`
        : process.env.NEXT_PUBLIC_APP_ENV === 'development'
        ? `https://api.[--REGION--].driffle.com`
        : 'http://localhost:3000',
    sellerServer:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? 'https://seller.prod.[--REGION--].driffle.com/seller'
        : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        ? 'https://api.[--REGION--].driffle.com/seller'
        : process.env.NEXT_PUBLIC_APP_ENV === 'development'
        ? 'https://seller.[--REGION--].driffle.com/seller'
        : 'http://localhost:3001/seller',
    frontendEvent:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? 'https://events.driffle.com'
        : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        ? 'https://events.driffle.dev'
        
        : process.env.NEXT_PUBLIC_APP_ENV === 'development'
        ? 'https://events.dev.driffle.com'
        : 'http://localhost:3004',
    search:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? 'https://search.prod.[--REGION--].driffle.com'
        : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
        ? 'https://search.[--REGION--].driffle.com'
        : process.env.NEXT_PUBLIC_APP_ENV === 'development'
        ? 'https://search.[--REGION--].driffle.com'
        : 'http://localhost:3005',
    support:
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? 'https://support.central.driffle.com'
        : 'http://support.central.driffle.com',
    career: 'https://careers.driffle.com/wp-json/jobs/v1',
    careerLocal: 'http://localhost/myspace/jobs/wp-json/jobs/v1',
  },
  frontend:
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://driffle.com'
      : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://driffle.dev'
      : process.env.NEXT_PUBLIC_APP_ENV === 'development'
      ? 'https://web.dev.driffle.com'
      : 'http://localhost:4001',
  server:
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://api.driffle.com'
      : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://api.driffle.dev'
      : process.env.NEXT_PUBLIC_APP_ENV === 'development'
      ? 'https://api.dev.driffle.com'
      : 'http://localhost:3000',
  sellerServer:
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://seller.driffle.com/seller'
      : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://api.driffle.dev/seller'
      : process.env.NEXT_PUBLIC_APP_ENV === 'development'
      ? 'https://seller.dev.driffle.com/seller'
      : 'http://localhost:3001/seller',
  frontendEvent:
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://events.driffle.com'
      : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://events.driffle.dev'
      : process.env.NEXT_PUBLIC_APP_ENV === 'development'
      ? 'https://events.dev.driffle.com'
      : 'http://localhost:3004',
  search:
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://search.driffle.com'
      : process.env.NEXT_PUBLIC_APP_ENV === 'staging'
      ? 'https://search.driffle.dev'
      : process.env.NEXT_PUBLIC_APP_ENV === 'development'
      ? 'https://search.dev.driffle.com'
      : 'https://search.dev.driffle.com',
  support:
    process.env.NEXT_PUBLIC_APP_ENV === 'production'
      ? 'https://support.central.driffle.com'
      : 'http://support.central.driffle.com',
  career: 'https://careers.driffle.com/wp-json/jobs/v1',
  careerLocal: 'http://localhost/myspace/jobs/wp-json/jobs/v1',
};
