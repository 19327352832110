import { getLocalStorageString } from './localStorageUtils';

export const findCountryDetails = (x: string | null) => {
  let countString = getLocalStorageString('countryArray');
  if (!countString) {
    return null;
  }
  try {
    let countArr = JSON.parse(JSON.parse(countString).value);
    if (Array.isArray(countArr)) {
      let country = countArr.find((c) => c.country_code === x);
      return country;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const findCurrencyDetails = (x: string | null) => {
  let countString = getLocalStorageString('countryArray');
  // let countString = getLocalStorageString('currencyArray');
  if (!countString) {
    return null;
  }
  try {
    let countArr = JSON.parse(JSON.parse(countString).value);
    if (Array.isArray(countArr)) {
      let country = countArr.find((c) => c.currency === x);
      return country;
    }
  } catch (e) {
    return null;
  }

  return null;
};
