export const getLocalStorageString = (key: string) => {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState == null) {
      return null;
    }
    return serializedState;
  } catch (err) {
    return null;
  }
};

// Getting Data Back from LS
export function getLocalStorage(key: string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const serializedState = localStorage.getItem(key);
    let localItemVal = {} as any;
    if (serializedState == null) {
      return null;
    }
    try {
      localItemVal = JSON.parse(serializedState);
      return localItemVal;
    } catch (e) {
      // return primitive data types such as normal strings
      return serializedState;
    }
  } catch (err) {
    // process.env.NODE_ENV === 'development' &&
    //   console.log('err in getLocalStorage:', err);
    return null;
  }
}

// Setting Data at LS
export function saveLocalStorage(key: string, state: object | string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    if (typeof state === 'object') {
      let serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } else {
      localStorage.setItem(key, state);
    }
  } catch (err) {
    // console.log('Some Problem With Local Storage Writing');
  }
}

// Removing Data at LS
export function deleteLocalStorage(key: string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
}

export function saveLocalStorageExp(
  key: string,
  jsonData: object | string,
  expirationMin: number
) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const expirationMS = expirationMin * 60 * 1000;
    const record = {
      value: JSON.stringify(jsonData),
      timestamp: new Date().getTime() + expirationMS,
    };
    localStorage.setItem(key, JSON.stringify(record));
    return jsonData;
  } catch (e) {
    return false;
  }
}

export function getLocalStorageExp(key: string) {
  if (typeof Storage === 'undefined') {
    return false;
  }
  try {
    const item = localStorage.getItem(key);
    if (item === null) {
      return false;
    }
    const record = JSON.parse(item);
    if (!record || !record.timestamp) {
      return false;
    }

    if (new Date().getTime() >= record.timestamp) {
      localStorage.removeItem(key); // delete the obsolete item
      return false;
    }
    return record.value;
    // return JSON.parse(record.value);
    /**
     * ak-TODO
     */
  } catch (e) {
    return false;
  }
}
