import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Dispatch } from 'redux';
import {
  CountryListInterface,
  CurrencyDetailsInterface,
  GeoLocationInterface,
  LocationDetailsInterface,
  PlatformListInterface,
  RegionInterface,
} from '../Interface/GlobalInterface';
import { apiConstants } from '../constants/apiConstants';

import Cookies from 'universal-cookie';
import {
  getLocalStorageExp,
  saveLocalStorageExp,
} from '../utils/localStorageUtils';
import {
  findCountryDetails,
  findCurrencyDetails,
} from '../utils/locationUtils';

const cookies = new Cookies();

export interface GlobalState {
  geoLocation: GeoLocationInterface | null;
  locationDetails: LocationDetailsInterface;
  deviceId: string;
  locationDetailsFetched: boolean;
  currencyDetails: CurrencyDetailsInterface;
  countryArray: CountryListInterface[];
  regionArray: RegionInterface[];
  platformArray: PlatformListInterface[];
}

const initialState: GlobalState = {
  locationDetailsFetched: false,
  geoLocation: null,
  locationDetails: {
    id: 1,
    status: 1,
    country_name: '',
    country_code: '',
    country_dialing: -1,
    currency_symbol: '',
    currency_name: '',
    currency: '',
    multiplier: 1,
    flag_country_code: '',
  },
  currencyDetails: {
    id: 1,
    currency_symbol: '€',
    currency_name: 'Euro',
    currency: 'EUR',
    multiplier: 1,
  },
  countryArray: [],
  regionArray: [],
  platformArray: [],
  deviceId: '',
};

export const fetchCountryList = () => async (dispatch: any) => {
  let countryArray = getLocalStorageExp('countryArray');

  // fetch country list from server if not available in local storage
  if (!countryArray || !countryArray.length) {
    await axios
      .get(apiConstants.search + '/countries', {
        withCredentials: true,
      })
      .then((response) => {
        const allCountries = response.data.countryList;

        const allowedCountries = allCountries;

        saveLocalStorageExp('countryArray', allowedCountries, 10);
        dispatch(setCountryArray(allowedCountries));
        // saveLocalStorageExp('currencyArray', response.data.currencyList, 60);
      })
      .catch((error) => {})
      .finally(() => {
        // dispatch(fetchGeoLocation());
      });
  } else {
    dispatch(setCountryArray(JSON.parse(countryArray)));
    // dispatch(fetchGeoLocation());
    return;
  }
};

// export const changeCurrencyRedux =
//   (currencyCode: string | null) => async (dispatch: any) => {
//     if (!currencyCode) {
//       return;
//     }
//     let locationWithCurrency = findCurrencyDetails(currencyCode);
//     if (
//       locationWithCurrency &&
//       locationWithCurrency.currency_symbol &&
//       locationWithCurrency.currency_name &&
//       locationWithCurrency.currency &&
//       locationWithCurrency.multiplier
//     ) {
//       dispatch(
//         changeCurrency({
//           currency_symbol: locationWithCurrency.currency_symbol,
//           currency_name: locationWithCurrency.currency_name,
//           currency: locationWithCurrency.currency,
//           multiplier: locationWithCurrency.multiplier,
//         })
//       );
//     }
//   };

export const fetchGeoLocation = () => async (dispatch: any) => {
  let isIpCountryValid = false;
  let countryArr = JSON.parse(getLocalStorageExp('countryArray'));
  dispatch(unsetLocationDetailsFetched());
  let inintialLocationDetails = JSON.parse(
    JSON.stringify(initialState.locationDetails)
  );
  const userLocation = cookies.get('user-ip-location');
  if (userLocation && userLocation?.country) {
    let locationWithCountry = findCountryDetails(userLocation.country);

    if (locationWithCountry) {
      isIpCountryValid = true;
    }

    let x: any = {
      country_code: userLocation.country,
      country_name: locationWithCountry?.country_name || userLocation.country,
    };
    dispatch(changeGeoLocation(x));
    if (locationWithCountry) {
      inintialLocationDetails = locationWithCountry;
    }
  }

  const selectedCountry: LocationDetailsInterface =
    cookies.get('selected_country');

  if (selectedCountry && selectedCountry.country_code) {
    dispatch(changeGloablStateToSelectedCountry());
    dispatch(setLocationDetailsFetched());
    return;
  }

  let currencyToBe = cookies.get('currency');
  if (currencyToBe) {
    let locationWithCurrency = findCurrencyDetails(currencyToBe);
    if (
      locationWithCurrency &&
      locationWithCurrency.currency_symbol &&
      locationWithCurrency.currency_name &&
      locationWithCurrency.currency &&
      locationWithCurrency.multiplier
    ) {
      inintialLocationDetails.currency_symbol =
        locationWithCurrency.currency_symbol;
      inintialLocationDetails.currency_name =
        locationWithCurrency.currency_name;
      inintialLocationDetails.currency = locationWithCurrency.currency;
      inintialLocationDetails.multiplier = locationWithCurrency.multiplier;
    }
  } else {
    // cookies.set('currency', )
  }

  if (!selectedCountry && !isIpCountryValid && countryArr.length > 0) {
    // dispatch(setCurrency('EUR', 'OTH'));
    let locationWithCountry = findCountryDetails('OTH');
    if (locationWithCountry) {
      inintialLocationDetails = locationWithCountry;
    }
    let x: any = {
      country_code: 'OTH',
      country_name: 'Others',
    };
    dispatch(changeGeoLocation(x));
  }

  // console.log(inintialLocationDetails);
  dispatch(changeLocationDetails(inintialLocationDetails));
  dispatch(setLocationDetailsFetched());
};

export const changeGloablStateToSelectedCountry =
  () => async (dispatch: Dispatch) => {
    const selectedCountry: LocationDetailsInterface =
      cookies.get('selected_country');
    const flagCountryCode = cookies.get('flag_country_code');

    // console.log(selectedCountry);
    let inintialLocationDetails: any = JSON.parse(
      JSON.stringify(initialState.locationDetails)
    );

    if (selectedCountry) {
      inintialLocationDetails.country_code = selectedCountry.country_code;
      inintialLocationDetails.country_dialing = selectedCountry.country_dialing;
      inintialLocationDetails.country_name = selectedCountry.country_name;
      inintialLocationDetails.currency = selectedCountry.currency;
      inintialLocationDetails.currency_name = selectedCountry.currency_name;
      inintialLocationDetails.currency_symbol = selectedCountry.currency_symbol;
      inintialLocationDetails.id = selectedCountry.id;
      inintialLocationDetails.multiplier = selectedCountry.multiplier;
      inintialLocationDetails.status = selectedCountry.status;
      inintialLocationDetails.flag_country_code =
        flagCountryCode  || selectedCountry.country_code;

      // console.log(inintialLocationDetails);

      dispatch(changeLocationDetails(inintialLocationDetails));
    }
  };

/**
 *
 * @returns Fetch the mapping of region-id to region names and save to localstorage
 */
export const fetchRegionsList = () => async (dispatch: Dispatch) => {
  let regionArray: any = getLocalStorageExp('regionsArray');
  if (!regionArray || !regionArray.length) {
    await axios
      .get(apiConstants.server + '/public/regions/get-regions', {
        withCredentials: true,
      })
      .then((response) => {
        saveLocalStorageExp('regionsArray', response.data.data, 60);
        dispatch(setRegionArray(response.data.data));
      })
      .catch((error) => {});
  } else {
    dispatch(setRegionArray(JSON.parse(regionArray)));
    return;
  }
};

/**
 *
 * @returns Fetch the mapping of platform-icon to platform names and save to localstorage
 */

export const fetchPlatformList = () => async (dispatch: Dispatch) => {
  let platformArray = getLocalStorageExp('platformsArray');
  if (!!platformArray && platformArray.length) {
    dispatch(setPlatformArray(JSON.parse(platformArray)));
    return;
  }

  await axios
    .get(apiConstants.server + '/public/platforms?limit=1000', {
      withCredentials: true,
    })
    .then((response) => {
      saveLocalStorageExp('platformsArray', response.data.data.platforms, 60);
      dispatch(setPlatformArray(response.data.data.platforms));
    })
    .catch((error) => {});
};

export const setCurrency =
  (currency: string, countryCode: string) => async (dispatch: any) => {
    dispatch(unsetLocationDetailsFetched());
    cookies.set('currency', currency, {
      path: '/',
    });
    await axios
      .get(apiConstants.server + `/public/set-country/${countryCode}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(changeGloablStateToSelectedCountry());
        }
      })
      .catch((error) => {
        // throw Error('error setting currency');
      });

    dispatch(setLocationDetailsFetched());
  };

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeCurrency: (state: GlobalState, action: any) => {
      state.locationDetails.currency = action.payload.currency;
      state.locationDetails.currency_name = action.payload.currency_name;
      state.locationDetails.currency_symbol = action.payload.currency_symbol;
      state.locationDetails.multiplier = action.payload.multiplier;
    },
    changeGeoLocation: (state: GlobalState, action: any) => {
      state.geoLocation = action.payload;
    },
    changeLocationDetails: (state: GlobalState, action: any) => {
      state.locationDetails = action.payload;
    },
    defaultState: (state: GlobalState) => {
      state = initialState;
    },
    setLocationDetailsFetched: (state: GlobalState) => {
      state.locationDetailsFetched = true;
    },
    unsetLocationDetailsFetched: (state: GlobalState) => {
      state.locationDetailsFetched = false;
    },
    setRegionArray: (state: GlobalState, action: any) => {
      state.regionArray = action.payload;
    },
    setCountryArray: (state: GlobalState, action: any) => {
      state.countryArray = action.payload;
    },
    setPlatformArray: (state: GlobalState, action: any) => {
      state.platformArray = action.payload;
    },
    setGeoLocation: (state: GlobalState, action: any) => {
      state.geoLocation = action.payload;
      state.locationDetailsFetched = true;
    },
    setLocationDetails: (state: GlobalState, action: any) => {
      state.locationDetails = action.payload;
      state.locationDetailsFetched = true;
    },
    setDeviceId: (state: GlobalState, action: any) => {
      state.deviceId = action.payload;
    },
    setCurrencyDetails: (state: GlobalState, action: any) => {
      state.currencyDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeCurrency,
  changeGeoLocation,
  changeLocationDetails,
  defaultState,
  setLocationDetailsFetched,
  unsetLocationDetailsFetched,
  setRegionArray,
  setCountryArray,
  setPlatformArray,
  setGeoLocation,
  setLocationDetails,
  setDeviceId,
  setCurrencyDetails,
} = globalSlice.actions;

export default globalSlice.reducer;
