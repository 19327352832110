import { GtagItemInterface } from '../Interface/GtagInterface';
import { OrderInfoDetailsInterface } from '../pages/checkout/success';
import { triggerGtmESimPurchase, triggerGtmPaymentSuccess } from './gtm';
import { getSkuFromSlug } from './productDetailsUtils';

export const triggerPurchaseEvent = (
  orderDetails: OrderInfoDetailsInterface,
  cartType: 'marketplace' | 'eSim'
) => {
  try {
    const orderDetailsProducts = orderDetails?.items || [];
    if (orderDetailsProducts && orderDetailsProducts.length > 0) {
      let itemsArray: Array<GtagItemInterface> = [];
      const productIds: Array<string> = [];
      const productNames: Array<string> = [];
      const fbObjectPurchase: Array<{
        id: string;
        quantity: number;
        name: string;
      }> = [];
      for (let i = 0; i < orderDetailsProducts.length; i++) {
        itemsArray.push({
          item_id: getSkuFromSlug(orderDetailsProducts[i].slug),
          item_name: orderDetailsProducts[i].title,
          google_business_vertical: 'retail',
          price: orderDetailsProducts[i].totalPriceBase,
          currency: 'EUR',
          //   item_category2: orderDetailsProducts[i]?.platform || '',
          quantity: 1,
          // basePrice: orderDetailsProducts[i].price,
        });
        productIds.push(getSkuFromSlug(orderDetailsProducts[i].slug));
        productNames.push(orderDetailsProducts[i].title);
        fbObjectPurchase.push({
          id: getSkuFromSlug(orderDetailsProducts[i].slug),
          // quantity: orderDetailsProducts[i]?.quantity || 1,
          quantity: 1,
          name: orderDetailsProducts[i]?.title,
        });
      }
      // const eventData = {
      //   event: cartType === 'marketplace' ? 'purchase' : 'esim_purchase',
      //   ecommerce: {
      //     page:
      //       cartType === 'marketplace'
      //         ? 'checkout/confirmation'
      //         : 'checkout/success',
      //     transaction_id: orderDetails?.orderId || '',
      //     value: String(orderDetails?.orderAmountBase) || '',
      //     currency: 'EUR',
      //     orderEmail:
      //       orderDetails?.custEmail || orderDetails?.billingEmail || '',
      //     items: itemsArray,
      //     fbObjectPurchase,
      //   },
      // };

      if (cartType === 'marketplace') {
        triggerGtmPaymentSuccess({
          orderAmount: orderDetails.orderAmountBase,
          orderEmail:
            orderDetails?.custEmail || orderDetails?.billingEmail || '',
          orderId: orderDetails.orderId,
          orderItems: orderDetails.items,
          page: 'checkout/success',
          currency: 'EUR',
          fbObjectPurchase,
        });
        //  @ts-ignore
        fbq(
          'track',
          'Purchase',
          {
            value: orderDetails.finalOrderAmount,
            currency: 'EUR',
            content_ids: orderDetails.items?.map((el: any) => el.productSku),
            content_name: orderDetails.items?.map((el: any) => el.title),
            contents: orderDetails.items?.map((el: any) => ({
              id: el.productSku,
              quantity: 1,
            })),
          },
          { eventID: orderDetails.orderId }
        );
      } else {
        triggerGtmESimPurchase({
          orderAmount: orderDetails.orderAmountBase,
          orderEmail:
            orderDetails?.custEmail || orderDetails?.billingEmail || '',
          orderId: orderDetails.orderId,
          orderItems: orderDetails.items,
          page: 'checkout/success',
          currency: 'EUR',
        });
        //@ts-ignore
        fbq(
          'trackCustom',
          'Purchase_ESim',
          {
            value: orderDetails.finalOrderAmount,
            currency: 'EUR',
            content_ids: orderDetails.items?.map((el: any) => el.productSku),
            content_name: orderDetails.items?.map((el: any) => el.title),
            contents: orderDetails.items?.map((el: any) => ({
              id: el.productSku,
              quantity: 1,
            })),
          },
          { eventID: orderDetails.orderId }
        );
      }
    }
  } catch (error) {
    throw error;
  }
};
