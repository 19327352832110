import axios from 'axios';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import { apiConstants } from '../constants/apiConstants';
import { setAcquisitionChannel } from '../utils/acquisitionChannel';
import { getPageData, saveEventV3 } from '../utils/eventTracking';
import { setItmRoutes } from '../utils/itmTracking';
import { deleteLocalStorage } from '../utils/localStorageUtils';
const cookies = new Cookies();

function useBaseTracking() {
  const router = useRouter();
  const [historyState, setHistoryState] = useState<NextRouter[]>([]);

  // for affiliate tracking start
  useEffect(() => {
    if (
      router.query.utm_source &&
      (router.query.utm_source === 'admitad' || // admitad
        router.query.utm_source === 'awin' || // awin
        router.query.utm_source === 'daisycon' || // daisycon
        // router.query.utm_source === 'Taboola' || // taboola
        // router.query.utm_source === 'cj' || // cj
        router.query.utm_source === 'facebook-ads' || // facebook-ads
        router.query.utm_source === 'driffleaff' || // postAffiliatePro
        router.query.utm_source === 'google-shopping' || // google shopping
        router.query.utm_source === 'facebook-shopping' || // facebook shopping
        router.query.utm_source === 'snapchat-shopping') // snapchat shopping
    ) {
      deleteLocalStorage('dd_cookie');
      cookies.remove('dd_cookie');
    }
  }, [router.query]);
  // for affiliate tracking end

  // set drif_acq cookie
  useEffect(() => {
    if (router.isReady) {
      let utmCookie;
      let campaignCookie;

      // for google shopping
      if (router.query.srsltid && typeof router.query.srsltid === 'string') {
        utmCookie = 'google-shopping';
      }

      // for facebook shopping
      if (router.query.fbclid && typeof router.query.fbclid === 'string') {
        utmCookie = 'facebook-shopping';
      }

      // for snapchat shopping
      if (router.query.ScCid && typeof router.query.ScCid === 'string') {
        utmCookie = 'snapchat-shopping';
      }

      // for setting utm_source
      if (
        router.query.utm_source &&
        typeof router.query.utm_source === 'string'
      ) {
        utmCookie = router.query.utm_source;

        // for CJ Event ID
        // if (router.query.utm_source === 'cj' && router.query.cjevent) {
        //   axios
        //     .get(
        //       apiConstants.frontend +
        //         '/api/cookie/cj?cjevent=' +
        //         router.query.cjevent
        //     )
        //     .then(() => {})
        //     .catch(() => {});
        // }
      }

      // for setting utm_campaign
      if (
        router.query.utm_campaign &&
        typeof router.query.utm_campaign === 'string'
      ) {
        campaignCookie = router.query.utm_campaign;
      }

      // for postAffiliatePro refid is set to drif_acq_cam
      if (
        router.query.refid &&
        typeof router.query.refid === 'string' &&
        router.query.utm_source === 'driffleaff'
      ) {
        campaignCookie = router.query.refid;
      }

      let uri = apiConstants.frontend + '/api/cookie?';

      if (utmCookie || campaignCookie) {
        if (utmCookie) {
          uri += 'source=' + utmCookie + '&';
        }

        if (campaignCookie) {
          uri += 'campaign=' + campaignCookie;
        }
        axios
          .get(uri)
          .then(() => {})
          .catch(() => {});
      }
    }
  }, [router]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const pageData = getPageData(router);
    saveEventV3({
      category: pageData.pageName,
      action: 'page_view',
      label: pageData.pageLabel,
      properties: '',
      value: [pageData.pageValue],
      jsonData: {
        query: pageData.query,
      },
      from: historyState[historyState.length - 1] || '',
    });

    setHistoryState([...historyState, router]);
  }, [router.asPath, router.isReady]);

  useEffect(() => {
    if (router !== undefined) {
      setItmRoutes(router?.asPath);
    }
  }, [router]);

  const acqRef = useRef<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && router.isReady && !acqRef.current) {
      setAcquisitionChannel(router);
      acqRef.current = true;
    }
  }, [JSON.stringify(router)]);

  return {};
}

export default useBaseTracking;
