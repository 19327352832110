/* eslint-disable @next/next/no-img-element */
import { memo } from 'react';
import { NoJSMessageContainer, NoJSMessageText } from './NoJSMessageStyle';

function NoJSMessage() {
  return (
    <noscript className="noJs">
      <NoJSMessageContainer>
        <img width="33" src="/icons/caution-24.svg" alt="Js disabled" />
        <NoJSMessageText>
          If you&apos;re seeing this message, that means{' '}
          <strong>JavaScript has been disabled on your browser</strong>. Please
          enable JS to make this app work.
        </NoJSMessageText>
      </NoJSMessageContainer>
    </noscript>
  );
}

export default memo(NoJSMessage);
