import { createContext, useContext, useState } from 'react';

interface AnalyticsContextI {
  // cleverTap: CleverTap | null;
  cleverTap: boolean; // true - not used - just to avoid error in codebase
  setCleverTap: any;
}

const AnalyticsContext = createContext<AnalyticsContextI>({
  cleverTap: false,
  setCleverTap: () => {},
});

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export const AnalyticsProvider = ({ children }: any) => {
  const [cleverTap, setCleverTap] = useState<boolean>(false);

  // useEffect(() => {
  //   setCleverTap(true);
  // }, []);

  // const cleverTapInit = useCallback(async () => {
  //   const cleverTap = await initializeCleverTap();
  //   setCleverTap(cleverTap);
  // }, []);

  // useEffect(() => {
  //   cleverTapInit();
  // }, [cleverTapInit]);

  return (
    <AnalyticsContext.Provider
      value={{
        cleverTap,
        setCleverTap,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
