import {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useAppSelector } from '../redux/hooks';

interface PageLoaderInitialStateInterface {
  state: boolean;
}

const pageLoaderInitialState: PageLoaderInitialStateInterface = {
  state: false,
};

export interface PageLoaderActionInterface {
  type: 'show' | 'hide';
}

const pageLoaderReducer = (
  state: PageLoaderInitialStateInterface,
  action: PageLoaderActionInterface
): PageLoaderInitialStateInterface => {
  switch (action.type) {
    case 'show':
      const showState: PageLoaderInitialStateInterface = {
        state: true,
      };
      return showState;
    case 'hide':
      const hideState: PageLoaderInitialStateInterface = {
        state: false,
      };
      return hideState;
    default:
      return pageLoaderInitialState;
  }
};

interface Props {
  children: ReactElement;
}

const PageLoaderContext = createContext<{
  pageLoaderState: PageLoaderInitialStateInterface;
  dispatchPageLoader: (action: PageLoaderActionInterface) => void;
}>({ pageLoaderState: pageLoaderInitialState, dispatchPageLoader: () => {} });
const { Provider } = PageLoaderContext;

const PageLoaderProvider = (props: Props) => {
  const cart = useAppSelector((state) => state.cart);
  const [pageLoaderState, dispatchPageLoader] = useReducer(
    pageLoaderReducer,
    pageLoaderInitialState
  );

  useEffect(() => {
    if (cart.isFetching === 'fetched' || cart.isFetching === 'not-fetched') {
      setTimeout(() => {
        dispatchPageLoader({ type: 'hide' });
      }, 400);
    }
  }, [cart.isFetching]);

  const value = useMemo(() => {
    return { pageLoaderState, dispatchPageLoader };
  }, [pageLoaderState, dispatchPageLoader]);

  return <Provider value={value}>{props.children}</Provider>;
};

export { PageLoaderContext, PageLoaderProvider };
