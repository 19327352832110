export const DefectiveKeyRatio = (ratio: number) => {
  switch (true) {
    case ratio < 0.2:
      return {
        value: '< 0.2%',
        color: '#20B61D', 
      };
    case ratio >= 0.2 && ratio < 0.4:
      return {
        value: '0.2-0.4%',
        color: '#76C938',
      };
    case ratio >= 0.4 && ratio < 0.6:
      return {
        value: '0.4-0.6%',
        color: '#B8D74D', 
      };
    case ratio >= 0.6 && ratio < 0.8:
      return {
        value: '0.6-0.8%',
        color: '#FFDF3D',
      };
    case ratio >= 0.8 && ratio < 1:
      return {
        value: '0.8-1%',
        color: '#FFD857',
      };
    case ratio >= 1 && ratio < 1.2:
      return {
        value: '1-1.2%',
        color: '#FFCF50',
      };
    case ratio >= 1.2 && ratio < 1.4:
      return {
        value: '1.2-1.4%',
        color: '#FFB446', 
      };
    case ratio >= 1.4 && ratio < 1.6:
      return {
        value: '1.4-1.6%',
        color: '#FFA23F', 
      };
    case ratio >= 1.6 && ratio < 1.8:
      return {
        value: '1.6-1.8%',
        color: '#F88A3B', 
      };
    case ratio >= 1.8 && ratio <= 2:
      return {
        value: '1.8-2%',
        color: '#E76738', 
      };
    default:
      return {
        value: '> 2%',
        color: '#CF3333',
      };
  }
};

export const formatNumber = (num: number) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + 'M';
  }
  // Check if the number is a thousand or more
  else if (num >= 1000) {
    return (num / 1000).toFixed(0) + 'K';
  }
  // If the number is less than a thousand
  else {
    return num.toString();
  }
};

// export function monthsUntilNow(inputDateString:any) {
//     // Parse the input date string
//     // const inputDate = new Date(inputDateString);

//     // // Get the current date
//     // const currentDate = new Date();

//     // // Calculate the difference in months
//     // const months = (currentDate.getFullYear() - inputDate.getFullYear()) * 12 + currentDate.getMonth() - inputDate.getMonth();

//     // // Check if the current day is before the day of the input date in its month
//     // // If so, subtract a month from the difference
//     // if (currentDate.getDate() < inputDate.getDate()) {
//     //     return months - 1
//     // }

//     const month = 1000 * 60 * 60 * 24 * 30;

//     const inputDate = new Date(inputDateString);

//     const months = parseInt(
//       ((Date.now() - inputDate.valueOf()) / month).toString()
//     ) + 1;

//     return months
// }

export function monthsUntilNow(inputDateString: any) {
  // Parse the input date string
  const inputDate = new Date(inputDateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in months
  const months =
    (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    inputDate.getMonth();

  // Check if the current day is before the day of the input date in its month
  // If so, subtract a month from the difference
  if (currentDate.getDate() < inputDate.getDate()) {
    return months - 1 || 1;
  }

  return months || 1;
}

export const ellipseString = (name: string, charCount: number) => {
  return name?.length > charCount ? name.slice(0, charCount) + '...' : name;
};
