import axios from 'axios';
import { NextRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { CartResponseInterface } from '../Interface/CartItemInterface';
import { EventInputV3 } from '../Interface/EventsInterface';
import { GtagItemInterface } from '../Interface/GtagInterface';
import { apiConstants } from '../constants/apiConstants';
import { GTMEventNames } from './gtm';
import {
  getLocalStorage,
  getLocalStorageExp,
  getLocalStorageString,
} from './localStorageUtils';
import {
  getSessionStorageString,
  saveSessionStorage,
} from './sessionStorageUtils';

const cookies = new Cookies();

type EventTypes = 'gtm_event';
interface GAEventInterface {
  event: GTMEventNames;
  [key: string]: any;
}

const origin =
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : '';

export const trackEvent = (
  eventName: EventTypes,
  data: {
    type?: string;
    eventData: GAEventInterface;
  }
) => {
  if (origin !== 'https://driffle.com') {
    return;
  }

  switch (eventName) {
    case 'gtm_event': {
      gtmEvent(data.eventData);
      break;
    }
  }
};

const gtmEvent = (eventData: { event: GTMEventNames; [key: string]: any }) => {
  try {
    const userId = JSON.parse(getLocalStorageExp('nan_gen_uni')).userId;
    if (userId) {
      eventData['user_id'] = userId;
    }
    //@ts-ignore
    window &&
      //@ts-ignore
      window.dataLayer &&
      //@ts-ignore
      window.dataLayer.push(function () {
        //@ts-ignore
        this.reset();
      });
    //@ts-ignore
    window && window.dataLayer && window.dataLayer.push(eventData);
  } catch (error) {
    throw error;
    /**
     *
     */
  }
};

export const generateItemsArrayAndValue = (
  cart: CartResponseInterface,
  multiplier: number = 1,
  currency: string | undefined | null
) => {
  let totalValue: number = 0;
  let itemsArray: Array<GtagItemInterface> = [];

  if (Array.isArray(cart.cartItems)) {
    for (let i = 0; i < cart.cartItems.length; i++) {
      if (typeof cart.cartItems[i].price === 'number') {
        totalValue = totalValue + cart.cartItems[i].price;
      }
      // let xz = cart.cartItems[i].q
      itemsArray.push({
        item_id: cart.cartItems[i].product.productSku,
        item_name: cart.cartItems[i].product.title,
        google_business_vertical: 'retail',
        // item_category2: cart.cartItems[i].product.platform,
        // price: cart.cartItems[i].price * multiplier,
        // currency: currency || 'EUR',
        price: cart.cartItems[i].price,
        currency: 'EUR',
        basePrice: cart.cartItems[i].price,
        quantity: cart.cartItems[i].qty,
      });
    }
  }
  totalValue = totalValue * multiplier;
  return {
    totalValue,
    itemsArray,
  };
};

// export const generateDevice = async () => {
//   if (!getLocalStorageString('events-device')) {
//     const url = `${apiConstants.frontendEvent}/gen-device`;
//     const res = await axios.get(url, {
//       withCredentials: true,
//     });
//     saveLocalStorage('events-device', res.data.deviceId);
//     return res.data.deviceId;
//   }
//   return getLocalStorageString('events-device');
// };

export const generateDevice = async () => {
  if (!cookies.get('device_uid')) {
    const url = `${apiConstants.frontend}/api/device`;
    const res = await axios.get(url, {
      withCredentials: true,
    });

    return res.data.deviceId;
  }
  return cookies.get('device_uid');
};

export const generateSession = async () => {
  if (!getSessionStorageString('events-session')) {
    const url = `${apiConstants.frontendEvent}/session`;
    const res = await axios.get(url, {
      withCredentials: true,
    });
    saveSessionStorage('events-session', res.data.sessionId);
    return res.data.sessionId;
  }
  return getSessionStorageString('events-session');
};

const getScreenResolution = () => {
  const resolution: { height: number | null; width: number | null } = {
    height: null,
    width: null,
  };
  try {
    if (typeof window !== 'undefined') {
      resolution.height = window.innerHeight;
      resolution.width = window.innerWidth;
    }
  } catch (e) {
    return resolution;
  }
  return resolution;
};

export const setNsureCookie = (nsureDeviceId: string | undefined) => {
  if (cookies.get('nsure-device-id-bck') !== nsureDeviceId) {
    axios
      .post(
        apiConstants.server + '/public/set-nsure-cookie',
        {
          nsureDeviceId,
        },
        { withCredentials: true }
      )
      .then((res) => {})
      .catch((err) => {});
  }
};

export const saveEventV3 = async (
  { category, action, label, properties, value, from, jsonData }: EventInputV3,
  router?: NextRouter
) => {
  try {
    const url = `${apiConstants.frontendEvent}/event`;

    let deviceId = cookies.get('device_uid');
    let sessionId = getSessionStorageString('events-session');
    if (!deviceId) deviceId = await generateDevice();
    if (!sessionId) sessionId = await generateSession();
    const userData = getLocalStorage('user_data');

    let nsureDeviceId = undefined;
    try {
      // @ts-ignore
      nsureDeviceId = window?.nSureSDK?.getDeviceId();
      if (typeof nsureDeviceId === 'string') {
        setNsureCookie(nsureDeviceId);
      } else {
        /**
         * if nsuresdk hasn't been initialized then wait for 2 seconds to try again
         */
        setTimeout(() => {
          // @ts-ignore
          nsureDeviceId = window?.nSureSDK?.getDeviceId();
          if (typeof nsureDeviceId === 'string') {
            setNsureCookie(nsureDeviceId);
          }
        }, 10000);
      }
    } catch (e) {}

    let checkoutEmail = getLocalStorageString('checkout-email');
    let checkoutCustId = getLocalStorageString('checkout-custId');

    const clientData = {
      deviceId,
      sessionId,
      device: navigator.userAgent,
      platform: (navigator as any)?.userAgentData?.platform ?? 'web',
      platformType: 'web',
      screenResolution: getScreenResolution(),
      clientTimestamp: Date.now(),
      userData: userData
        ? {
            cust_id: userData.cust_id,
            email: userData.email,
            store_id: userData?.store_id ?? null,
          }
        : checkoutEmail && checkoutCustId
        ? {
            cust_id: Number(checkoutCustId),
            email: String(checkoutEmail),
          }
        : 'guest',
      nsureDeviceId,
    };

    const parseCookie = (str: string) => {
      const cookies = {};
      str.split(';').forEach((cookie: string) => {
        const parts = cookie.split('=');
        const key = decodeURIComponent(parts[0].trim());
        const value = decodeURIComponent(parts[1].trim());
        // @ts-ignore
        cookies[key] = value;
      });
      return cookies;
    };

    const postData: any = {
      data: {
        clientData,
        category,
        action,
        label,
        properties,
        value,
        from: from ? getPageData(from).pageName : '',
        version: 3,
      },
      cookies: parseCookie(document.cookie),
    };

    if (jsonData) {
      postData.data.jsonData = jsonData;
    }

    try {
      if ((window as any)?.ReactNativeWebView) {
        (window as any)?.ReactNativeWebView?.postMessage(
          JSON.stringify({ event: postData })
        );
        return;
      }
    } catch (error) {}

    const res = await axios.post(url, postData, { withCredentials: true });
  } catch (err) {}
};

export const getPageData = (router: NextRouter) => {
  const query = router.query;
  let pageName = router.asPath.substring(1);
  let pageLabel = '';
  let pageValue = '';

  switch (router.pathname) {
    //home page
    case '/':
      pageName = 'home';
      break;

    //slug page
    case '/[slug]':
      if (typeof router.query.slug === 'string') {
        pageName = 'pdp';
        pageValue = router.query.slug;
      }
      break;

    //store pages
    case '/store':
      pageName = 'store';
      break;

    case '/store/[type]/[value]':
      pageName = 'store';
      pageLabel = router.query.type as string;
      pageValue = router.query.value as string;
      break;

    case '/store/collection/[type]':
      pageName = 'store';
      pageLabel = 'collection';
      pageValue = router.query.type as string;
      break;

    case '/store/collection/deals-of-the-day':
      pageName = 'store';
      pageLabel = 'collection';
      pageValue = 'deals-of-the-day';
      break;

    //checkout pages
    case '/checkout':
      pageName = 'checkout';
      break;

    case '/checkout/success':
      pageName = 'checkout';
      pageLabel = 'success';
      break;

    case '/checkout/confirmation':
      pageName = 'checkout';
      pageLabel = 'confirmation';
      break;

    case '/checkout/retry':
      pageName = 'checkout';
      pageLabel = 'retry';
      break;

    case '/checkout/preparing':
      pageName = 'checkout';
      pageLabel = 'preparing';
      break;

    //cart page
    case '/cart':
      pageName = 'cart';
      break;

    // platform page
    case '/platform/[platform]':
      pageName = 'platform';
      pageValue = router.query.platform as string;
      break;

    //careers page
    case '/careers':
      pageName = 'careers';
      break;

    case '/careers/[positionId]':
      pageName = 'careers';
      pageValue = router.query.positionId as string;
      break;

    //product list page
    case '/product-list/[key]':
      pageName = 'product-list';
      pageValue = router.query.key as string;
      break;

    // sell-on-driffle page
    case '/sell-on-driffle':
      pageName = 'sell-on-driffle';
      break;

    case '/sell-on-driffle/[page]':
      pageName = 'sell-on-driffle';
      pageValue = router.query.page as string;
      break;

    case '/sell-on-driffle/verification':
      pageName = 'sell-on-driffle';
      pageLabel = 'verification';
      break;

    //support pages
    case '/support':
      pageName = 'support';
      break;

    case '/support/new-ticket':
      pageName = 'support';
      pageLabel = 'new-ticket';
      break;

    case '/support/write':
      pageName = 'support';
      pageLabel = 'write';
      break;

    case '/support/ticket/create':
      pageName = 'support';
      pageLabel = 'create-ticket';

    // vendor pages
    case '/vendor/[slug]':
      pageName = 'vendor';
      pageValue = router.query.slug as string;
      break;

    //user pages

    case '/user':
      pageName = 'user';
      break;

    case '/user/auth/forgot-password':
      pageName = 'user';
      pageLabel = 'auth';
      pageValue = 'forgot-password';
      break;

    case '/user/auth/login':
      pageName = 'user';
      pageLabel = 'auth';
      pageValue = 'login';
      break;

    case '/user/auth/signup':
      pageName = 'user';
      pageLabel = 'auth';
      pageValue = 'signup';
      break;

    case '/user/library':
      pageName = 'user';
      pageLabel = 'library';
      break;

    case '/user/wallet':
      pageName = 'user';
      pageLabel = 'wallet';
      break;

    case '/user/wallet/transactions/[id]':
      pageName = 'user';
      pageLabel = 'wallet-transaction-detail';
      break;

    case '/user/my-coupons':
      pageName = 'user';
      pageLabel = 'my-coupons';
      break;

    case '/user/orders':
      pageName = 'user';
      pageLabel = 'orders';
      break;

    case '/user/orders/[orderId]':
      pageName = 'user';
      pageLabel = 'orders';
      pageValue = router.query.orderId as string;
      break;

    //seller pages
    case '/user/selling/dashboard':
      pageName = 'selling';
      pageLabel = 'dashboard';
      break;

    case '/user/selling/currently-selling':
      pageName = 'selling';
      pageLabel = 'currently-selling';
      break;

    case '/user/selling/currently-selling/[offerId]':
      pageName = 'selling';
      pageLabel = 'currently-selling';
      pageValue = router.query.offerId as string;
      break;

    case '/user/selling/transactions':
      pageName = 'selling';
      pageLabel = 'transactions';
      break;

    case '/user/selling/withdrawals':
      pageName = 'selling';
      pageLabel = 'withdrawal';
      break;

    case '/user/selling/reports':
      pageName = 'selling';
      pageLabel = 'reports';
      break;

    case '/user/selling/reports/':
      pageName = 'selling';
      pageLabel = 'reports';
      break;

    case '/user/selling/tickets':
      pageName = 'selling';
      pageLabel = 'tickets';
      break;

    case '/user/selling-accounts/tickets':
      pageName = 'selling-accounts';
      pageLabel = 'tickets';
      break;

    case '/user/selling-accounts/ticket-logs':
      pageName = 'selling-accounts';
      pageLabel = 'ticket_logs';
      break;

    case '/user/selling/ticket-logs':
      pageName = 'selling';
      pageLabel = 'ticket_logs';
      break;

    case '/user/selling/notifications':
      pageName = 'selling';
      pageLabel = 'notifications';
      break;

    case '/plus':
      pageName = 'plus';
      break;

    case '/plus/checkout':
      pageName = 'plus';
      pageLabel = 'checkout';
      break;

    case '/user/membership':
      pageName = 'membership';
      break;

    case '/user/membership/billing':
      pageName = 'membership';
      pageLabel = 'billing';
      break;

    case '/esim':
      pageName = 'esims_home';
      break;

    case '/esim/[slug]':
      pageName = 'esims_pdp';
      pageLabel = router.query.slug as string;
      break;

    case '/esim/[slug]':
      pageName = 'esims_pdp';
      break;

    //checkout pages
    case '/esim/checkout':
      pageName = 'esims_checkout';
      break;

    case '/esim/checkout/preparing':
      pageName = 'esims_checkout';
      pageLabel = 'preparing';
      break;

    case '/esim/checkout/success':
      pageName = 'esims_checkout';
      pageLabel = 'success';
      break;

    case '/esim/compatibility':
      pageName = 'esims_compatibility';
      break;

    case '/esim/faqs':
      pageName = 'esims_faqs';
      break;

    case '/esim/how-to-install':
      pageName = 'esims_how_to_install';
      break;

    case '/esim/privacy-policy':
      pageName = 'esims_privacy_policy';
      break;

    case '/esim/search':
      pageName = 'esims_search';
      break;

    case '/esim/support':
      pageName = 'esims_support';
      break;

    case '/esim/terms-and-conditions':
      pageName = 'esims_terms_and_conditions';
      break;

    case '/esim/account/profile':
      pageName = 'esims_account';
      pageLabel = 'profile';
      break;

    case '/esim/account/plan-details':
      pageName = 'esims_account';
      pageLabel = 'plan-details';
      break;

    case '/esim/account/orders':
      pageName = 'esims_account';
      pageLabel = 'orders';
      break;

    case '/esim/account/orders/activation':
      pageName = 'esims_account';
      pageLabel = 'orders';
      pageValue = 'activation';
      break;

    case '/esim/account/orders/[esimsOid]':
      pageName = 'esims_account';
      pageLabel = 'orders';
      pageValue = router.query.esimsOid as string;
      break;

    case '/esim/account/library/[esimsOid]':
      pageName = 'esims_account';
      pageLabel = 'library';
      pageValue = router.query.esimsOid as string;
      break;
    case '/esim/account/library':
      pageName = 'esims_account';
      pageLabel = 'library';
      break;

    default:
      pageName = router.pathname.split('/')[1] ?? '';
      break;
  }

  return {
    query,
    pageName,
    pageLabel,
    pageValue,
  };
};

// export const saveEvent = async (event: string, data: any) => {
//   try {
//     const url = `${apiConstants.frontendEvent}/add-event`;

//     let deviceId = getLocalStorageString('events-device');
//     let sessionId = getSessionStorageString('events-session');
//     if (!deviceId) deviceId = await generateDevice();
//     if (!sessionId) sessionId = await generateSession();
//     let user = getLocalStorage('user_data');
//     const res = await axios.post(
//       url,
//       {
//         sessionId: sessionId,
//         deviceId: deviceId,
//         type: event,
//         metaData: {
//           source: location.href,
//           device: navigator.userAgent,
//           redirected_from: document.referrer,
//           country: cookies.get('user-ip-location')?.country || 'US',
//           currency: cookies.get('currency') || '-',
//           userId: user?.cust_id || null,
//           ...data,
//         },
//         timestamp: Date.now(),
//         screenResolution: getScreenResolution(),
//       },
//       { withCredentials: true }
//     );
//   } catch (err) {}
// };

export const parsePathnameForEvents = (path: string) => {
  let pageName;

  if (path[0] === '/') {
    return path;
  }

  switch (path) {
    case '/':
      pageName = 'home';
      break;

    case '/[slug]':
      pageName = 'pdp';
      break;

    case '/store':
    case '/store/[type]/[value]':
      pageName = 'store';
      break;

    case '/checkout/success':
      pageName = 'checkout_success';
      break;

    case '/checkout/confirmation':
      pageName = 'checkout_confirmation';
      break;

    case '/checkout':
      pageName = 'checkout';
      break;

    case '/cart':
      pageName = 'cart';
      break;

    case '/favourites':
      pageName = 'favourites';
      break;

    default:
      // Handle cases when the router pathname does not match any known routes
      pageName = path;
      break;
  }
  return pageName;
};
