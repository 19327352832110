import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { NextRouter } from 'next/router';
import { Dispatch } from 'redux';
import {
  UserDetailsInterface,
  UserSubscriptionStatus,
} from '../Interface/UserDetailsInterface';
import { apiConstants } from '../constants/apiConstants';
import { getSubscriptionABTestMethods } from '../utils/abTest';
import { saveEventV3 } from '../utils/eventTracking';
import {
  deleteLocalStorage,
  getLocalStorageString,
  saveLocalStorage,
} from '../utils/localStorageUtils';
import { saveSubscriptionToCookies } from '../utils/membership';
import {
  WE_USER_EVENT_LOGIN_METHOD_SELECT,
  WE_USER_EVENT_SOCIAL_LOGIN_INITIATE,
  WE_USER_EVENT_TRACK_USER,
  WE_USER_LOGIN,
  WE_USER_LOGOUT,
  WE_USER_REGISTER,
} from '../utils/we';
import {
  closeLoginModalRedux,
  closeRegisterModalRedux,
  openForgotPasswordMessageRedux,
  openLoginModalRedux,
  openValidateEmailMessageRedux,
  saveTempUserEmail,
} from './modalSlice';

// Define a type for the slice state
interface UserState {
  user: UserDetailsInterface | null | undefined;
  isFetching: boolean;
  isError: boolean;
  isLoggedIn: boolean;
  errorMessage: string;
  redirectUrl: string | null;
  is2FARequired: boolean;
  is2FAVerified: boolean;
  loginStatus: 'new' | 'existing';
  subscription: UserSubscriptionStatus;
}

interface registerInput {
  email: string;
  password: string;
  confirm_password: string;
  router: NextRouter;
  cleverTap: any;
}

interface loginInput {
  email: string;
  password: string;
  redirectUrl?: string | null;
  router: NextRouter;
  cleverTap: any;
}

interface ErrorActionInterface {
  payload: { isError: boolean; errorMessage: string };
}

interface InvalidEmailActionInterface {
  payload: string;
}

// Define the initial state using that type
const initialState: UserState = {
  user: undefined,
  isFetching: true,
  isError: false,
  isLoggedIn: false,
  errorMessage: '',
  redirectUrl: null,
  is2FARequired: false,
  is2FAVerified: false,
  loginStatus: 'new',
  subscription: 'no',
};

export const loginUser = createAsyncThunk(
  'public/customer/login',
  async (
    { email, password, redirectUrl = null, router, cleverTap }: loginInput,
    thunkAPI
  ) => {
    thunkAPI.dispatch(fetchingDetails());
    const data = {
      email: email,
      password: password,
    };

    WE_USER_EVENT_SOCIAL_LOGIN_INITIATE('email');

    await axios
      .post(apiConstants.server + '/public/customer/login', data, {
        withCredentials: true,
      })
      .then((response) => {
        thunkAPI.dispatch(saveUser(response.data.customer));
        saveLocalStorage('user_data', response.data.customer);
        thunkAPI.dispatch(closeLoginModalRedux());

        if (getSubscriptionABTestMethods() === 'yes') {
          if (
            response?.data?.customer?.drifflePlusMembershipDetails
              ?.membership_status === 'ACTIVE'
          ) {
            saveSubscriptionToCookies('active');
            thunkAPI.dispatch(saveSubscriptionStatus('active'));
          } else {
            thunkAPI.dispatch(saveSubscriptionStatus('inactive'));
            saveSubscriptionToCookies('inactive');
          }
        } else {
          if (
            response?.data?.customer?.drifflePlusMembershipDetails
              ?.membership_status === 'ACTIVE'
          ) {
            saveSubscriptionToCookies('active');
            thunkAPI.dispatch(saveSubscriptionStatus('active'));
          } else {
            thunkAPI.dispatch(saveSubscriptionStatus('no'));
            saveSubscriptionToCookies('no');
          }
        }

        // we track user
        WE_USER_LOGIN(
          {
            id: response.data.customer.user_enc,
            email: response.data.customer.email,
          },
          cleverTap
        );
        WE_USER_EVENT_TRACK_USER(response.data.customer, cleverTap);

        const we_socialLogin = getLocalStorageString('we_sl');
        if (we_socialLogin) {
          WE_USER_EVENT_LOGIN_METHOD_SELECT(
            {
              method: we_socialLogin,
            },
            cleverTap
          );
        }
        saveEventV3({
          action: 'click',
          category: 'header',
          label: 'login',
          properties: we_socialLogin ? we_socialLogin : 'email',
          value: [email],
          from: router,
        });

        if (redirectUrl && window) {
          window.location.href = redirectUrl;
        }
        return response.data.customer;
      })
      .catch((err) => {
        if (getSubscriptionABTestMethods() === 'yes') {
          saveSubscriptionToCookies('inactive');
          thunkAPI.dispatch(saveSubscriptionStatus('inactive'));
        } else {
          saveSubscriptionToCookies('no');
          thunkAPI.dispatch(saveSubscriptionStatus('no'));
        }

        thunkAPI.dispatch(saveTempUserEmail(email));
        if (
          err?.response?.data?.status === 400 &&
          err?.response?.data?.reason === '2FA_NEEDED'
        ) {
          thunkAPI.dispatch(
            set2FARequired({
              is2FARequired: true,
              user: err?.response?.data?.customer,
            })
          );
        } else if (err?.response?.data?.msg) {
          thunkAPI.dispatch(loginError(err?.response?.data?.msg));
          if (err.response.data.reason === 'EMAIL_NOT_VERIFIED') {
            thunkAPI.dispatch(saveTempUserEmail(email));
            thunkAPI.dispatch(openValidateEmailMessageRedux());
          }
        } else {
          thunkAPI.dispatch(loginError('Server Error. Please try again later'));
        }
      });
  }
);

export const registerUser = createAsyncThunk(
  'public/customer/register',
  async (
    { email, password, confirm_password, router, cleverTap }: registerInput,
    thunkAPI
  ) => {
    thunkAPI.dispatch(fetchingDetails());
    const data = {
      email: email,
      password: password,
      confirm_password: confirm_password,
    };
    await axios
      .post(apiConstants.server + '/public/customer/register', data, {
        withCredentials: true,
        timeout: 5 * 1000,
      })
      .then((response) => {
        let res = response.data;

        thunkAPI.dispatch(noUser());

        saveEventV3({
          action: 'click',
          category: 'header',
          label: 'signup',
          properties: 'email',
          value: [email],
          from: router,
        });

        WE_USER_REGISTER(
          {
            email: email,
          },
          cleverTap
        );

        thunkAPI.dispatch(openValidateEmailMessageRedux());
        thunkAPI.dispatch(saveTempUserEmail(email));
      })
      .catch((err) => {
        if (err?.response?.data?.msg) {
          thunkAPI.dispatch(loginError(err?.response?.data?.msg));
        } else {
          thunkAPI.dispatch(loginError('Server Error. Please try again later'));
        }
      });
  }
);

export const fetchUser = () => async (dispatch: Dispatch) => {
  dispatch(fetchingDetails());

  axios
    .get(apiConstants.server + '/private/customer/get-profile', {
      withCredentials: true,
    })
    .then((response) => {
      saveLocalStorage('user_data', response.data);
      dispatch(saveUser(response.data));

      if (getSubscriptionABTestMethods() === 'yes') {
        if (
          response?.data?.drifflePlusMembershipDetails?.membership_status ===
          'ACTIVE'
        ) {
          saveSubscriptionToCookies('active');
          dispatch(saveSubscriptionStatus('active'));
        } else {
          dispatch(saveSubscriptionStatus('inactive'));
          saveSubscriptionToCookies('inactive');
        }
      } else {
        if (
          response?.data?.drifflePlusMembershipDetails?.membership_status ===
          'ACTIVE'
        ) {
          saveSubscriptionToCookies('active');
          dispatch(saveSubscriptionStatus('active'));
        } else {
          dispatch(saveSubscriptionStatus('no'));
          saveSubscriptionToCookies('no');
        }
      }

      // SET CHECKOUT EMAIL AND CUSTID TO LOCAL STORAGE
      saveLocalStorage('checkout-email', String(response.data?.email));
      saveLocalStorage('checkout-custId', String(response.data.cust_id));
      saveLocalStorage('checkout-user_enc', String(response.data.user_enc));

      // we track user
      // WE_USER_EVENT_TRACK_USER(response.data);

      dispatch(closeRegisterModalRedux());
      dispatch(closeLoginModalRedux());
    })
    .catch((error) => {
      // remove subscription from cookies
      if (getSubscriptionABTestMethods() === 'yes') {
        saveSubscriptionToCookies('inactive');
        dispatch(saveSubscriptionStatus('inactive'));
      } else {
        saveSubscriptionToCookies('no');
        dispatch(saveSubscriptionStatus('no'));
      }

      deleteLocalStorage('user_data');
      dispatch(noUser());
      // dispatch(closeRegisterModalRedux());
      // dispatch(closeLoginModalRedux());
    });
};

export const logoutUserServer =
  (router: NextRouter, cleverTap: any) => async (dispatch: Dispatch) => {
    axios
      .get(apiConstants.server + '/public/customer/logout', {
        withCredentials: true,
      })
      .then((response) => {
        // for RN webview logout
        try {
          if ((window as any)?.ReactNativeWebView) {
            (window as any)?.ReactNativeWebView?.postMessage(
              JSON.stringify({ action: 'LOGOUT_WEBVIEW' })
            );
          }
        } catch (error) {}

        deleteLocalStorage('user_data');
        deleteLocalStorage('checkout-email');
        deleteLocalStorage('checkout-custId');
        deleteLocalStorage('checkout-user_enc');

        // remove subscription from cookies
        if (getSubscriptionABTestMethods() === 'yes') {
          saveSubscriptionToCookies('inactive');
          dispatch(saveSubscriptionStatus('inactive'));
        } else {
          saveSubscriptionToCookies('no');
          dispatch(saveSubscriptionStatus('no'));
        }

        WE_USER_LOGOUT(cleverTap);

        saveEventV3({
          action: 'click',
          category: 'header',
          label: 'sign_out',
          properties: '',
          value: [],
          from: router,
        });

        dispatch(logoutUser());
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(
          set2FARequired({
            is2FARequired: false,
            user: null,
          })
        );
        // dispatch(fetchCarts())
        // window.location.href = '/';
      });
  };

export const googleOneTapLogin =
  (idToken: string, router: NextRouter, cleverTap: any) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchingDetails());

    try {
      const res = await axios.post(
        apiConstants.server + '/social-auth/googleOneTap/one-tap',
        {
          user: idToken,
        },
        {
          withCredentials: true,
        }
      );
      dispatch(saveUser(res.data.customer));
      saveLocalStorage('user_data', res.data.customer);
      if (getSubscriptionABTestMethods() === 'yes') {
        if (
          res?.data?.customer?.drifflePlusMembershipDetails
            ?.membership_status === 'ACTIVE'
        ) {
          saveSubscriptionToCookies('active');
          dispatch(saveSubscriptionStatus('active'));
        } else {
          dispatch(saveSubscriptionStatus('inactive'));
          saveSubscriptionToCookies('inactive');
        }
      } else {
        if (
          res?.data?.customer?.drifflePlusMembershipDetails
            ?.membership_status === 'ACTIVE'
        ) {
          saveSubscriptionToCookies('active');
          dispatch(saveSubscriptionStatus('active'));
        } else {
          dispatch(saveSubscriptionStatus('no'));
          saveSubscriptionToCookies('no');
        }
      }

      const we_socialLogin = getLocalStorageString('we_sl');
      WE_USER_LOGIN(
        {
          id: res.data.customer.user_enc,
          email: res.data.customer.email,
        },
        cleverTap
      );
      WE_USER_EVENT_TRACK_USER(res.data.customer, cleverTap);

      if (we_socialLogin) {
        WE_USER_EVENT_LOGIN_METHOD_SELECT(
          {
            method: we_socialLogin,
          },
          cleverTap
        );
      }

      saveEventV3({
        action: 'click',
        category: 'header',
        label: 'google_one_tap',
        properties: '',
        value: [res.data.customer.email],
        from: router,
      });
    } catch (err: any) {
      deleteLocalStorage('user_data');
      dispatch(noUser());
      if (
        err?.response?.data?.status === 400 &&
        err?.response?.data?.reason === '2FA_NEEDED'
      ) {
        dispatch(
          set2FARequired({
            is2FARequired: true,
            user: err?.response?.data?.customer,
          })
        );
        dispatch(openLoginModalRedux());
      }

      if (getSubscriptionABTestMethods() === 'yes') {
        saveSubscriptionToCookies('inactive');
        dispatch(saveSubscriptionStatus('inactive'));
      } else {
        saveSubscriptionToCookies('no');
        dispatch(saveSubscriptionStatus('no'));
      }
    }
  };

export const generateResetPasswordToken =
  (email: string) => async (dispatch: Dispatch) => {
    dispatch(fetchingDetails());
    axios
      .post(
        apiConstants.server + '/public/customer/generate-reset-password-token',
        {
          email: email,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        dispatch(openForgotPasswordMessageRedux());
      })
      .catch((error) => {
        if (error?.response?.data?.msg) {
          dispatch(loginError(error?.response?.data?.msg));
        } else {
          dispatch(loginError('Server Error'));
        }
      })
      .finally(() => {
        dispatch(unsetFetchingDetails());
      });
  };

export const sendEmailVerification =
  (email: string) => async (dispatch: Dispatch) => {
    dispatch(fetchingDetails());
    axios
      .post(
        apiConstants.server + '/public/customer/send-email-verification',
        {
          email: email,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        dispatch(openValidateEmailMessageRedux());
      })
      .catch((error) => {
        dispatch(loginError(error?.response?.data?.msg));
      })
      .finally(() => {
        dispatch(unsetFetchingDetails());
      });
  };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.isError = false;
      state.isFetching = false;
      state.isLoggedIn = true;
      state.user = action.payload;
      state.errorMessage = '';
    },
    fetchingDetails: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    unsetFetchingDetails: (state) => {
      state.isFetching = false;
    },
    loginError: (state, action: InvalidEmailActionInterface) => {
      state.isError = true;
      state.isFetching = false;
      state.isLoggedIn = false;
      state.errorMessage = action.payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.isFetching = false;
      state.isError = false;
      state.isLoggedIn = false;
      state.errorMessage = '';
    },

    noUser: (state) => {
      state.isFetching = false;
      state.user = null;
      state.isError = false;
      state.isLoggedIn = false;
      state.errorMessage = '';
    },
    setError: (state, action: ErrorActionInterface) => {
      state.isError = action.payload.isError;
      state.errorMessage = action.payload.errorMessage;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    removeRedirectUrl: (state) => {
      state.redirectUrl = null;
    },
    set2FARequired: (state, action) => {
      state.is2FARequired = action.payload.is2FARequired;
      state.isFetching = false;
      state.user = action.payload.user;
    },
    saveLoginStatus: (state, action) => {
      state.loginStatus = action.payload;
    },
    saveSubscriptionStatus: (state, action) => {
      state.subscription = action.payload;
    },
    set2FAVerified: (state, action) => {
      state.is2FAVerified = action.payload;
    },
  },
});

export const {
  saveUser,
  fetchingDetails,
  unsetFetchingDetails,
  loginError,
  logoutUser,
  noUser,
  setError,
  setRedirectUrl,
  removeRedirectUrl,
  set2FARequired,
  set2FAVerified,
  saveLoginStatus,
  saveSubscriptionStatus,
} = userSlice.actions;

export default userSlice.reducer;
