import axios from 'axios';
import Cookies from 'universal-cookie';
import { apiConstants } from '../constants/apiConstants';
import { UserSubscriptionStatus } from '../Interface/UserDetailsInterface';
import { OrderInfoDetailsInterface } from '../pages/checkout/success';
import { getProductRecommendationType } from './abTest';

import {
  getLocalStorage,
  getLocalStorageExp,
  getLocalStorageString,
  saveLocalStorage,
  saveLocalStorageExp,
} from './localStorageUtils';

const cookies = new Cookies();

const baseUrlInteract = apiConstants.frontendEvent + '/interact';
const baseUrlRecommend = apiConstants.search + '/recommend';
const baseUrlSearch = apiConstants.search + '/recommend/search';

export const sendRecombeeUserSetEvent = async ({
  country,
  currency,
  language,
  region,
  subscription,
}: {
  country: string;
  currency: string;
  language: string;
  region: string;
  subscription: UserSubscriptionStatus;
}) => {
  const deviceId = cookies.get('device_uid');

  let userId = getLocalStorageString('checkout-user_enc');

  userId = userId
    ? userId === 'null'
      ? null
      : getLocalStorageString('user_enc')
    : null;

  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';

  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;
      if (userIdentifier) {
        const payload = {
          country,
          currency,
          language,
          region,
          subscription,
        };

        const res = await axios.post(
          baseUrlInteract + '/users/' + userIdentifier,
          payload,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          saveLocalStorage('rru', '1');
        }
      }
    }
  } catch (error) {
    console.error('Error sending UserSet event to Recombee:', error);
  }
};

export const sendRecombeeAddToCartEvent = async (
  action: 'add' | 'remove',
  qty: number,
  productSku: string,
  price?: number
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';

  if (!ifRecombeeEnabled) {
    return;
  }

  const deviceId = cookies.get('device_uid');
  let userId = getLocalStorageString('checkout-user_enc');
  userId = userId
    ? userId === 'null'
      ? null
      : getLocalStorageString('user_enc')
    : null;
  let recommId = getRecombeeRecommendationIdBySku(String(productSku));

  try {
    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

      if (userIdentifier) {
        const payload: any = {
          user: userIdentifier,
          pId: productSku,
          action: action,
          price: Number(price),
        };

        if (recommId) {
          payload['recommId'] = recommId;
        }

        const res = await axios.post(baseUrlInteract + '/cart', payload, {
          withCredentials: true,
        });
        if (res.status === 200) {
          if (action === 'add') {
            saveLocalStorageExp(
              'rrFlag',
              {
                status: 0,
                timestamp: Date.now(),
              },
              30
            );
          }
        }
      }
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const sendProductDetailViewEvent = async (
  productSku: string,
  tag: string
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }
  let getAllPtagsViewed = getLocalStorageExp('ptagViewed') ?? null;
  let parsedPtagsViewed = getAllPtagsViewed
    ? JSON.parse(getAllPtagsViewed) ?? []
    : [];

  // console.log('parsedPtagsViewed', parsedPtagsViewed);
  if (parsedPtagsViewed && !parsedPtagsViewed.includes(tag)) {
    // console.log('parsedPtagsViewed', parsedPtagsViewed);
    parsedPtagsViewed = [...parsedPtagsViewed, tag];
    saveLocalStorageExp('ptagViewed', parsedPtagsViewed, 60 * 24 * 2);
  }

  // console.log(
  //   'parsedPtagsViewed',
  //   JSON.parse(getLocalStorageExp('ptagViewed'))
  // );

  if (parsedPtagsViewed.length > 1) {
    saveLocalStorageExp(
      'rrFlag',
      {
        status: 0,
        timestamp: Date.now(),
      },
      30
    );
    // reset ptagViewed array to empty array
    saveLocalStorageExp('ptagViewed', [], 60 * 24 * 2);
  }

  try {
    const deviceId = cookies.get('device_uid');
    let userId = getLocalStorageString('checkout-user_enc');
    userId = userId
      ? userId === 'null'
        ? null
        : getLocalStorageString('user_enc')
      : null;

    let recommId = getRecombeeRecommendationIdBySku(String(productSku));

    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

      if (userIdentifier) {
        const payload: any = {
          user: userIdentifier,
          pId: productSku,
        };
        if (recommId) {
          payload['recommId'] = recommId;
        }

        await axios.post(baseUrlInteract + '/view', payload, {
          withCredentials: true,
        });
      }
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const sendRecombeeBookmarkEvent = async (
  productSku: string,
  action: 'add' | 'remove'
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    const deviceId = cookies.get('device_uid');
    let userId = getLocalStorageString('checkout-user_enc');
    userId = userId
      ? userId === 'null'
        ? null
        : getLocalStorageString('user_enc')
      : null;
    let recommId = getRecombeeRecommendationIdBySku(String(productSku));

    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

      if (userIdentifier) {
        const payload: any = {
          user: userIdentifier,
          pId: productSku,
          action,
        };
        if (recommId) {
          payload['recommId'] = recommId;
        }
        const res = await axios.post(baseUrlInteract + '/wishlist', payload, {
          withCredentials: true,
        });

        if (res.status === 200) {
          if (action === 'add') {
            saveLocalStorageExp(
              'rrFlag',
              {
                status: 0,
                timestamp: Date.now(),
              },
              30
            );
          }
        }
      }
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const sendRecombeePurchaseInteractionEvent = async (
  orderDetails: OrderInfoDetailsInterface
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    const deviceId = cookies.get('device_uid');
    let userId = getLocalStorageString('checkout-user_enc');
    userId = userId
      ? userId === 'null'
        ? null
        : getLocalStorageString('user_enc')
      : null;

    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

      if (userIdentifier) {
        const payload = {
          user: userIdentifier,
          data: orderDetails.items.map((item) => {
            let recommId = getRecombeeRecommendationIdBySku(
              String(item.productSku)
            );

            let body: any = {
              pId: item.productSku,
              price: item.totalPriceBase,
              amount: item.quantity,
            };

            if (recommId) {
              body['recommId'] = recommId;
            }

            return body;
          }),
        };
        const res = await axios.post(baseUrlInteract + '/purchase', payload, {
          withCredentials: true,
        });

        if (res.status === 200) {
          saveLocalStorageExp(
            'rrFlag',
            {
              status: 0,
              timestamp: Date.now(),
            },
            30
          );
        }

        // removeRecombeeRecommendationIdBySku
        orderDetails.items.forEach((item) => {
          removeRecombeeRecommendationIdBySku(String(item.productSku));
        });
      }
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const sendMergeRecombeeUserEvent = async (userId: string) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    const deviceId = cookies.get('device_uid');
    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

      if (userIdentifier) {
        await axios.get(
          baseUrlInteract + `/user/${userIdentifier}/${deviceId}`,
          {
            withCredentials: true,
          }
        );
      }
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const getUserRecommendations = async (
  scenario: string,
  fromPage: string,
  limit: number,
  country: string
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return null;
  }

  try {
    let result = null;
    const deviceId = cookies.get('device_uid');
    let userId = getLocalStorageString('checkout-user_enc');
    userId = userId
      ? userId === 'null'
        ? null
        : getLocalStorageString('user_enc')
      : null;
    if (typeof window !== 'undefined') {
      const getAllSavedRecommendations = getLocalStorageExp('rrItems') ?? [];
      const parsedRecommendations = getAllSavedRecommendations
        ? JSON.parse(getAllSavedRecommendations)
        : [];
      // console.log('parsedRecommendations', parsedRecommendations);

      // if (!userId) return;

      let getSavedRecommendations = parsedRecommendations[scenario];

      // console.log('getSavedRecommendations', getSavedRecommendations);

      const ifRrFlagPresent = getLocalStorageExp('rrFlag') ?? null;
      const parsedRrFlag = ifRrFlagPresent ? JSON.parse(ifRrFlagPresent) : null;

      // console.log('parsedRrFlag', parsedRrFlag);

      if (
        parsedRrFlag &&
        parsedRrFlag.status === 1 &&
        getSavedRecommendations
      ) {
        if (
          getSavedRecommendations &&
          getSavedRecommendations.list &&
          getSavedRecommendations.list.length > 0
        ) {
          result = getSavedRecommendations;
        }
      } else {
        const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

        if (userIdentifier) {
          const { status, data } = await axios.get(
            baseUrlRecommend + `/list/${userIdentifier}`,
            {
              withCredentials: true,
              params: {
                scenario,
                fromPage,
                limit,
                country,
              },
            }
          );
          if (status === 200) {
            // saveLocalStorageExp('home')

            saveLocalStorageExp(
              'rrItems',

              {
                ...parsedRecommendations,
                [scenario]: { ...data.data, timestamp: Date.now() },
              },
              30
            );

            saveLocalStorageExp(
              'rrFlag',
              {
                status: 1,
                timestamp: Date.now(),
              },
              30
            );

            result = data.data;
          }
        }
      }
      return result;
    }
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const getProductRecommendations = async (
  productSku: string,
  scenario: string,
  fromPage: string,
  country: string
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    // let isUserSet = getLocalStorageString('rru') ?? '0';

    const deviceId = cookies.get('device_uid');
    let userId = getLocalStorageString('checkout-user_enc');
    userId = userId
      ? userId === 'null'
        ? null
        : getLocalStorageString('user_enc')
      : null;

    const getAllSavedRecommendations = getLocalStorageExp('rrItems') ?? [];
    const parsedRecommendationsScenerio = getAllSavedRecommendations
      ? JSON.parse(getAllSavedRecommendations)
      : [];
    const parsedRecommendations =
      (parsedRecommendationsScenerio &&
        parsedRecommendationsScenerio[scenario]) ??
      [];

    // console.log('parsedRecommendations', parsedRecommendations);

    if (!productSku) return;

    let getSavedRecommendations =
      parsedRecommendations &&
      parsedRecommendations?.filter(
        (item: any) => item.productSku === productSku
      );

    // console.log('getSavedRecommendations', getSavedRecommendations);
    //check if we have saved recommendations for this product
    if (getSavedRecommendations && getSavedRecommendations.length > 0) {
      // console.log('returning saved recommendations');
      return getSavedRecommendations[0];
    } else {
      // console.log('fetching new recommendations');
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;
      if (userIdentifier) {
        const { status, data } = await axios.get(
          baseUrlRecommend + `/list/${productSku}/${userIdentifier}`,
          {
            withCredentials: true,
            params: {
              scenario,
              fromPage,
              country,
            },
          }
        );
        if (status === 200) {
          let recombeeRecommendations = { ...data.data };
          recombeeRecommendations['productSku'] = productSku;

          parsedRecommendations.push(recombeeRecommendations);

          if (parsedRecommendations.length > 3) {
            let updatedRecommendations = parsedRecommendations.slice(1);
            // console.log('updatedRecommendations', updatedRecommendations);
            saveLocalStorageExp(
              'rrItems',
              {
                ...parsedRecommendationsScenerio,
                [scenario]: updatedRecommendations,
              },
              30
            );
          } else {
            // console.log('parsedRecommendations', parsedRecommendations);
            saveLocalStorageExp(
              'rrItems',
              {
                ...parsedRecommendationsScenerio,
                [scenario]: parsedRecommendations,
              },
              30
            );
          }

          return data.data;
        }
      }
    }
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const saveSelectedRecommendations = (
  productSku: string,
  recommId: string
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    const rrList = getLocalStorage('rrList') ?? [];

    let recomObj = {
      recommId: recommId,
      itemId: productSku,
    };

    const ifSkuPresent = rrList.filter(
      (item: any) => String(item.itemId) === String(productSku)
    )[0];

    if (!ifSkuPresent) {
      rrList.push(recomObj);
      saveLocalStorage('rrList', rrList);
    } else {
      // update the productSku with new recommId
      let updatedList = rrList.map((item: any) => {
        if (item.itemId === productSku) {
          return {
            ...item,
            recommId: recommId,
          };
        } else {
          return item;
        }
      });
      saveLocalStorage('rrList', updatedList);
    }
  } catch (error) {}
};

const getRecombeeRecommendationIdBySku = (productSku: string) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }
  try {
    const rrList = getLocalStorage('rrList') ?? [];

    const ifSkuPresent = rrList.filter(
      (item: any) => String(item.itemId) === String(productSku)
    )[0];
    if (ifSkuPresent) {
      return ifSkuPresent.recommId;
    } else {
      return null;
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const removeRecombeeRecommendationIdBySku = (productSku: string) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';
  if (!ifRecombeeEnabled) {
    return;
  }
  try {
    const rrList = getLocalStorage('rrList') ?? [];

    const ifSkuPresent = rrList.filter(
      (item: any) => String(item.itemId) === String(productSku)
    )[0];
    if (ifSkuPresent) {
      let updatedList = rrList.filter(
        (item: any) => String(item.itemId) !== String(productSku)
      );
      saveLocalStorage('rrList', updatedList);
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const getRecombeeSearchResults = async (
  query: string,
  limit?: number
) => {
  const ifRecombeeEnabled = getProductRecommendationType() === 'recombee';

  if (!ifRecombeeEnabled) {
    return;
  }

  try {
    const deviceId = cookies.get('device_uid');
    let userId = getLocalStorageString('checkout-user_enc');
    userId = userId
      ? userId === 'null'
        ? null
        : getLocalStorageString('user_enc')
      : null;
    if (typeof window !== 'undefined') {
      const userIdentifier = userId ? 'd-' + String(userId) : deviceId;

      if (userIdentifier) {
        const { status, data } = await axios.get(baseUrlSearch, {
          withCredentials: true,
          params: {
            q: query,
            uId: userIdentifier,
            limit: limit ?? 8,
          },
        });
        if (status === 200) {
          return data;
        }
      }
    }
  } catch (error) {
    throw error;
  }
};
