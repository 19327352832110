import Cookies from 'universal-cookie';
import { getCookieDomain } from '../helper/helper';
import { getLocalStorageString, saveLocalStorage } from './localStorageUtils';

export type membershipTypes = 'monthly' | 'quarterly' | 'yearly';

export const membershipFeatures = [
  'Up to 10% OFF on Games, Gift Cards, DLCs and more.',
  'Access to exclusive sale events and promotions.',
  'Priority pre-order fulfillment',
  'Priority support',
];

export const membershipCategories = {
  monthly: {
    id: 91,
    title: 'Monthly',
    price: '1.49',
    mrp: '2.99',
    currency: 'EUR',
    note: 'Note: This is a 1-month recurring payment',
  },
  quarterly: {
    id: 92,
    title: 'Quarterly',
    price: '3.99',
    mrp: '7.99',
    currency: 'EUR',
    note: 'Note: This is a 3-month recurring payment',
  },
  yearly: {
    id: 93,
    title: 'Yearly',
    price: '14.99',
    mrp: '29.99',
    currency: 'EUR',
    note: 'Note: This is a 1-year recurring payment',
  },
};
export const membershipByMonths = (months: number) => {
  switch (months) {
    case 1:
      return 'monthly';
    case 3:
      return 'quarterly';
    case 12:
      return 'yearly';
    default:
      return 'monthly';
  }
};

export const buyMembership = (membershipType?: membershipTypes) => {
  try {
    let membership = membershipCategories['monthly'];
    membership = JSON.parse(
      getLocalStorageString('membership') || JSON.stringify(membership)
    );

    if (membershipType) {
      membership = membershipCategories[membershipType];
      saveLocalStorage('membership', JSON.stringify(membership));
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const isMembershipCheckoutPage = () => {
  const isMembershipPresentInCart = getLocalStorageString('membership') ?? '';
  let membership: any = membershipCategories['monthly'];
  if (isMembershipPresentInCart) {
    membership = JSON.parse(isMembershipPresentInCart);
  } else {
    membership = JSON.parse(JSON.stringify(membership));
    saveLocalStorage('membership', JSON.stringify(membership));
  }

  const isPageTypeMembershipCheckout =
    (typeof window !== 'undefined' &&
      window?.location?.pathname?.includes('/plus/checkout')) ||
    false;

  return isMembershipPresentInCart && isPageTypeMembershipCheckout;
};

export const getMembershipCheckoutDetails = (
  membershipType?: membershipTypes
) => {
  if (membershipType) {
    return membershipCategories[membershipType];
  }
  const membership: any = getLocalStorageString('membership') ?? '';
  return JSON.parse(membership);
};

const cookies = new Cookies();

export function saveSubscriptionToCookies(value: string) {
  cookies.set('subscription', value, {
    path: '/',
    domain: getCookieDomain(),
    expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
    secure: true,
    sameSite: 'none',
  });
}
